import { ReactComponentElement, useMemo } from 'react';
import { ReactComponent as Contact } from 'assets/icons/contact.svg';
import { ReactComponent as Help } from 'assets/icons/help.svg';
import { ReactComponent as Concept } from 'assets/icons/concept.svg';
import { ReactComponent as Exterior } from 'assets/icons/exterior.svg';
import { ReactComponent as Interior } from 'assets/icons/interior.svg';
import { ReactComponent as Overview } from 'assets/icons/overview.svg';
import { ReactComponent as Fullscreen } from 'assets/icons/fullscreen.svg';
import { ReactComponent as Layout } from 'assets/icons/layout.svg';
import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { ReactComponent as Door } from 'assets/icons/door.svg';
import { ReactComponent as Idea } from 'assets/icons/idea.svg';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Share } from 'assets/icons/share.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';

export enum Icons {
  Contact,
  Help,
  Concept,
  Exterior,
  Interior,
  Overview,
  Fullscreen,
  Layout,
  Heart,
  Door,
  Idea,
  Checkmark,
  Info,
  Close,
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  ChevronUp,
  ExternalLink,
  Eye,
  Share,
  Download,
}

interface IconProps {
  icon: Icons;
}

export const Icon: React.FC<IconProps> = ({ icon }) => {
  const element = useMemo((): ReactComponentElement<any> | null => {
    if (icon === Icons.Contact) return <Contact />;
    else if (icon === Icons.Help) return <Help />;
    else if (icon === Icons.Concept) return <Concept />;
    else if (icon === Icons.Exterior) return <Exterior />;
    else if (icon === Icons.Interior) return <Interior />;
    else if (icon === Icons.Overview) return <Overview />;
    else if (icon === Icons.Fullscreen) return <Fullscreen />;
    else if (icon === Icons.Layout) return <Layout />;
    else if (icon === Icons.Heart) return <Heart />;
    else if (icon === Icons.Door) return <Door />;
    else if (icon === Icons.Idea) return <Idea />;
    else if (icon === Icons.Checkmark) return <Checkmark />;
    else if (icon === Icons.Info) return <Info />;
    else if (icon === Icons.Close) return <Close />;
    else if (icon === Icons.ArrowLeft) return <ArrowLeft />;
    else if (icon === Icons.ArrowRight) return <ArrowRight />;
    else if (icon === Icons.ChevronDown) return <ChevronDown />;
    else if (icon === Icons.ChevronUp) return <ChevronUp />;
    else if (icon === Icons.ExternalLink) return <ExternalLink />;
    else if (icon === Icons.Eye) return <Eye />;
    else if (icon === Icons.Share) return <Share />;
    else if (icon === Icons.Download) return <Download />;
    else return null;
  }, [icon]);

  return (
    <>
      {element}
    </>
  );
};
