import { ConceptCollection, ConfiguratorOptions } from 'interfaces/Configurator';
import { get } from './ApiService';

export const getConfiguratorOptions = async (): Promise<ConfiguratorOptions> => {
  return get<ConfiguratorOptions>('/data/Alternatives.json');
};

export const getConcepts = async (): Promise<ConceptCollection> => {
  return get<ConceptCollection>('/data/Concepts.json');
};
