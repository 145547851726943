import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { Category, CategoryItem } from 'interfaces/Configurator';
import { Dropdown } from 'components/partial/Dropdown/Dropdown';
import { DropdownItemList } from 'components/partial/Dropdown/DropdownItemList/DropdownItemList';
import { DropdownRadioItem } from 'components/partial/Dropdown/DropdownItemList/DropdownRadioItem/DropdownRadioItem';
import { DropdownCheckboxItem } from 'components/partial/Dropdown/DropdownItemList/DropdownCheckboxItem/DropdownCheckboxItem';

interface ExteriorViewDropdownProps {
  category: Category;
}

export const ExteriorViewDropdown: React.FC<ExteriorViewDropdownProps> = ({ category }) => {
  const { t } = useTranslation();
  const { config, concept, updateConfigExteriorOption, updateConfigExteriorAddons } = useConfigurator();

  const options = useMemo((): CategoryItem[] => {
    if (!concept) return [];
    return category.options.filter((item: CategoryItem) => item.concepts.indexOf(concept.key) > -1);
  }, [category, concept]);

  const addons = useMemo((): CategoryItem[] => {
    if (!concept) return [];
    return category.addons.filter((item: CategoryItem) => item.concepts.indexOf(concept.key) > -1);
  }, [category, concept]);

  const selectedOptionId = useMemo((): number | null => {
    return config.exterior.selectedOptions[category.key].selectedOption;
  }, [config, category.key]);

  const selectedAddonIds = useMemo((): number[] => {
    return config.exterior.selectedOptions[category.key].selectedAddons;
  }, [config, category.key]);

  const onRadioClick = (item: CategoryItem): void => {
    updateConfigExteriorOption(category.key, item.id);
  };

  const onCheckboxClick = (item: CategoryItem): void => {
    updateConfigExteriorAddons(category.key, item.id);
  };

  if (!concept) {
    return null;
  }

  return (
    <Dropdown title={t(`categories.exterior.${category.key}.dropdownTitle`)}>
      <DropdownItemList>
        {options.map((item: CategoryItem) => {
          return (
            <li className="item" key={item.id}>
              <DropdownRadioItem
                label={t(`categories.exterior.${category.key}.options.${item.id}.label`)}
                cost={item.cost[concept.key].toLocaleString()}
                betterChoice={item.recommended}
                details={t(`categories.exterior.${category.key}.options.${item.id}.details`)}
                selected={item.id === selectedOptionId}
                item={item}
                onClick={onRadioClick}
                imageUrl={`images/concepts/exterior_${category.key}_${item.id}_option.png`}
              />
            </li>
          );
        })}
        {addons.map((item: CategoryItem) => {
          return (
            <li className="item" key={item.id}>
              <DropdownCheckboxItem
                label={t(`categories.exterior.${category.key}.options.${item.id}.label`)}
                cost={item.cost[concept.key].toLocaleString()}
                betterChoice={item.recommended}
                details={t(`categories.exterior.${category.key}.options.${item.id}.details`)}
                selected={selectedAddonIds.indexOf(item.id) > -1}
                value={item}
                onClick={onCheckboxClick}
                isAfterOptions={category.options.length > 0 && category.addons.length > 0}
              />
            </li>
          );
        })}
      </DropdownItemList>
    </Dropdown>
  );
};
