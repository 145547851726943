import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ChildGroupValues } from 'interfaces/Configurator';
import { OptionList } from '../OptionList';

interface ChildGroupOptionListProps {
  selectedChildGroup: ChildGroupValues;
  onChildGroupChange: (value: ChildGroupValues) => void;
}

export const ChildGroupOptionList: React.FC<ChildGroupOptionListProps> = ({ selectedChildGroup, onChildGroupChange }) => {
  const { t } = useTranslation();
  const { childGroups } = useConfigurator();

  return (
    <OptionList
      title={t('pages.configuratorPage.views.conceptView.childGroups.title')}
      options={childGroups}
      currentValue={selectedChildGroup}
      className="child_group_list"
      onItemChange={onChildGroupChange}
    />
  );
};
