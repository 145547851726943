import React, {useState} from 'react';
import {Icon, Icons} from '../Icon/Icon';
import './Dropdown.sass';

interface DropdownProps extends React.PropsWithChildren {
  title: string;
}

export const Dropdown: React.FC<DropdownProps> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onHeadClick = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dropdown ${isOpen ? '-open' : ''}`}>
      <button className="dropdown_header" onClick={onHeadClick}>
        <div className="title-wrapper">
          <h4 className="title">{title}</h4>
        </div>
        <div className="icon-wrapper">
          {isOpen ? <Icon icon={Icons.ChevronUp}/> : <Icon icon={Icons.ChevronDown} />}
        </div>
      </button>
      <div className="dropdown_body">
        {children}
      </div>
    </div>
  );
};
