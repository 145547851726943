import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { Icon, Icons } from 'components/partial/Icon/Icon';
import './ConfiguratorMenu.sass';

interface ConfiguratorMenuItem {
  label: string;
  step: ConfiguratorSteps;
  icon: Icons;
  separator: boolean;
  disabled: boolean;
}

export const ConfiguratorMenu: React.FC = () => {
  const { t } = useTranslation();
  const { step, setStep, isSubmitted } = useConfigurator();

  const menuItems: ConfiguratorMenuItem[] = [
    { label: t('common.concept'), step: ConfiguratorSteps.Concept, icon: Icons.Concept, separator: false, disabled: isSubmitted },
    { label: t('common.exterior'), step: ConfiguratorSteps.Exterior, icon: Icons.Exterior, separator: true, disabled: isSubmitted },
    { label: t('common.interior'), step: ConfiguratorSteps.Interior, icon: Icons.Interior, separator: true, disabled: isSubmitted },
    { label: t('common.overview'), step: ConfiguratorSteps.Overview, icon: Icons.Overview, separator: true, disabled: false },
  ];

  return (
    <nav id="configurator_menu">
      <ul className="menu">
        {menuItems.map((menuItem: ConfiguratorMenuItem) => {
          return (
            <li className={`item ${isSubmitted ? (menuItem.step === ConfiguratorSteps.Overview ? '-done' : '') : (menuItem.step <= step) ? '-active' : ''}`} key={menuItem.step}>
              {menuItem.separator ? <hr className="separator" /> : null}
              <button
                className="step_button"
                onClick={() => {setStep(menuItem.step);}}
                disabled={menuItem.disabled}
              >
                <Icon icon={menuItem.icon} />
                <span className="label">{menuItem.label}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
