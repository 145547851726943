import { Button } from 'components/partial/Button/Button';
import { Icons } from 'components/partial/Icon/Icon';
import './SquareButton.sass';

export enum SquareButtonStates {
  Default = '-default',
  Transparent = '-trans',
  Auto = '-auto',
  Large = '-large',
  Small = '-small',
}

export interface SquareButtonProps {
  label: string;
  icon: Icons;
  states?: SquareButtonStates[];
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
}

export const SquareButton: React.FC<SquareButtonProps> = ({ label, icon, states = [SquareButtonStates.Default, SquareButtonStates.Small], onClick, href, disabled = false }) => {
  return (
    <Button
      label={label}
      onClick={onClick}
      href={href}
      icon={icon}
      disabled={disabled}
      className={`square_button ${states.join(' ')}`}
    />
  );
};
