import { BetterChoiceBadge } from 'components/core/Badge/BetterChoiceBadge/BetterChoiceBadge';
import './DropdownCheckboxItem.sass';

interface DropdownCheckboxItemProps {
  label: string;
  cost: string;
  betterChoice: boolean;
  details: string;
  selected: boolean;
  value: any;
  onClick: (value: any) => void;
  isAfterOptions?: boolean;
}

export const DropdownCheckboxItem: React.FC<DropdownCheckboxItemProps> = ({ label, cost, betterChoice, details, selected, value, onClick, isAfterOptions = false }) => {
  if (value.visualize) {
    return (
      <button
        className={`dropdown_checkbox_item ${isAfterOptions ? '-small' : ''}`}
        onClick={() => {onClick(value);}}
      >
        <div className="input-wrapper">
          <span className={`input ${selected ? '-selected' : ''}`}></span>
        </div>
        <div className="content-wrapper">
          <div className="content">
            <div className="label-wrapper">
              <h6 className="label">{label}</h6>
              <span className="cost">(+ {cost} kr)</span>
              {betterChoice && <BetterChoiceBadge />}
            </div>
            <div className="detail-wrapper">
              <span className="detail">
                {details}
              </span>
            </div>
          </div>
        </div>
      </button>
    );
  }
  return null;
};
