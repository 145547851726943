import { useConfigurator } from 'hooks/useConfigurator';
import { FloorValues } from 'interfaces/Configurator';
import './LayoutList.sass';

interface LayoutListProps {
  large?: boolean;
}

export const LayoutList: React.FC<LayoutListProps> = ({ large = false }) => {
  const { concept, floor } = useConfigurator();

  if (!concept) {
    return null;
  }

  return (
    <ul id="layout_list" className="layout_list">
      <li className="item">
        <img src={`/images/concepts/${concept.key}/interior_layout_1f${large ? '_large' : ''}.jpg`} className="image" alt="" />
      </li>
      {floor === FloorValues.Two ? (
        <li className="item">
          <img src={`/images/concepts/${concept.key}/interior_layout_2f${large ? '_large' : ''}.jpg`} className="image" alt="" />
        </li>
      ) : null}
    </ul>
  );
};
