import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { useBiteriorView } from 'components/pages/ConfiguratorPage/BiteriorView/useBiteriorView';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { BiteriorViewLeftWrapper } from 'components/pages/ConfiguratorPage/BiteriorView/BiteriorViewLeftWrapper/BiteriorViewLeftWrapper';
import { Preview } from 'components/pages/ConfiguratorPage/Preview/Preview';
import { PreviewCanvas } from 'components/pages/ConfiguratorPage/Preview/PreviewCanvas/PreviewCanvas';
import { InteriorViewCanvas } from './InteriorViewCanvas/InteriorViewCanvas';
import { Anchor } from 'components/partial/Anchor/Anchor';
import { Icons } from 'components/partial/Icon/Icon';

export const InteriorViewLeftWrapper: React.FC = () => {
  const { t } = useTranslation();
  const { setStep } = useConfigurator();
  const { openFullScreen, openLayout } = useBiteriorView();

  return (
    <BiteriorViewLeftWrapper className="interior_view_left_wrapper">
      <div className="preview-wrapper">
        <Preview className="interior_view_preview">
          <PreviewCanvas
            className="interior_view_preview_canvas"
            information={{
              title: t('pages.configuratorPage.views.interiorView.preview.information.title'),
              text: t('pages.configuratorPage.views.interiorView.preview.information.text'),
            }}
            onFullScreenClick={openFullScreen}
            onLayoutClick={openLayout}
          >
            <InteriorViewCanvas />
          </PreviewCanvas>
        </Preview>
      </div>
      <div className="button-wrapper">
        <Anchor
          label={t('common.selectExterior')}
          icon={Icons.ArrowLeft}
          onClick={() => {setStep(ConfiguratorSteps.Exterior);}}
        />
      </div>
    </BiteriorViewLeftWrapper>
  );
};
