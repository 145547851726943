import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { LeftWrapper } from 'components/pages/ConfiguratorPage/LeftWrapper/LeftWrapper';
import { SubmitForm } from './SubmitForm/SubmitForm';
import { SuccessMessage } from './SuccessMessage/SuccessMessage';
import { Anchor } from 'components/partial/Anchor/Anchor';
import { Icons } from 'components/partial/Icon/Icon';
import './OverviewViewLeftWrapper.sass';

export const OverviewViewLeftWrapper: React.FC = () => {
  const { t } = useTranslation();
  const { setStep, isSubmitted } = useConfigurator();

  return (
    <LeftWrapper className="overview_view_left_wrapper">
      <div className="content-wrapper">
        {isSubmitted ? <SuccessMessage /> : <SubmitForm />}
      </div>
      {!isSubmitted && <div className="button-wrapper">
        <Anchor
          label={t('common.selectInterior')}
          icon={Icons.ArrowLeft}
          onClick={() => {
            setStep(ConfiguratorSteps.Interior);
          }}
        />
      </div>}
    </LeftWrapper>
  );
};
