import { useNavigate } from 'react-router-dom';
import { ConfiguratorMenu } from './ConfiguratorMenu/ConfiguratorMenu';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import Logo from 'assets/images/logo.svg';
import './Header.sass';

export const Header: React.FC = () => {
  const navigate = useNavigate();

  const onLogoClick = (): void => {
    navigate('/');
  };

  return (
    <header id="header">
      <div className="logo-wrapper" onClick={onLogoClick}>
        <img src={Logo} alt="Skanska Förskolebyggaren" className="logo" />
      </div>
      <div className="configurator_menu-wrapper">
        <ConfiguratorMenu />
      </div>
      <div className="header_menu-wrapper">
        <HeaderMenu />
      </div>
    </header>
  );
};
