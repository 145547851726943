import { BetterChoiceBadge } from 'components/core/Badge/BetterChoiceBadge/BetterChoiceBadge';
import './Configuration.sass';

interface ConfigurationProps extends React.PropsWithChildren {
  title: string;
  text: string;
  note?: string;
  className?: string;
}

export const Configuration: React.FC<ConfigurationProps> = ({ children, title, text, note, className }) => {
  return (
    <div id="configuration" className={className}>
      <div className="title-wrapper">
        <h3 className="title">{title}</h3>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <div className="text-wrapper">
            <p className="paragraph">{text}</p>
          </div>
          {note && <div className="note-wrapper">
            <div className="note">
              <div className="badge-wrapper">
                <BetterChoiceBadge/>
              </div>
              <div className="paragraph-wrapper">
                <p className="paragraph">{note}</p>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <div className="dropdown_list-wrapper">
        <div className="dropdown_list">
          {children}
        </div>
      </div>
    </div>
  );
};
