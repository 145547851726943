import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewCanvasInfo } from '../PreviewCanvasInfo/PreviewCanvasInfo';
import { SquareButton } from 'components/partial/Button/SquareButton/SquareButton';
import { Icons } from 'components/partial/Icon/Icon';
import './PreviewCanvasOverlay.sass';

interface PreviewCanvasOverlayProps {
  information: {
    title: string;
    text: string;
    note?: string;
  }
  onFullScreenClick: () => void;
  onLayoutClick: () => void;
}

export const PreviewCanvasOverlay: React.FC<PreviewCanvasOverlayProps> = ({ information, onFullScreenClick, onLayoutClick }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div id="preview_canvas_overlay">
      <PreviewCanvasInfo
        title={information.title}
        text={information.text}
        note={information.note}
        isOpen={isOpen}
        onClick={() => {setIsOpen(!isOpen);}}
      />
      <div id="preview_canvas_button_list">
        <SquareButton
          label={t('common.fullScreen')}
          icon={Icons.Fullscreen}
          onClick={onFullScreenClick}
        />
        <SquareButton
          label={t('common.layout')}
          icon={Icons.Layout}
          onClick={onLayoutClick}
        />
      </div>
    </div>
  );
};
