import { useMemo, useState } from 'react';
import { useConfigurator } from 'hooks/useConfigurator';
import { FloorValues } from 'interfaces/Configurator';
import { InteriorImageList } from './InteriorImageList/InteriorImageList';
import { Icon, Icons } from 'components/partial/Icon/Icon';
import './InteriorViewCanvas.sass';

interface InteriorViewCanvasProps {
  large?: boolean;
}

export const InteriorViewCanvas: React.FC<InteriorViewCanvasProps> = ({ large }) => {
  const { floor } = useConfigurator();

  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const pagination = useMemo((): number[] => {
    const items = [1,2,3,4,5,6,7];
    if (floor === FloorValues.Two) items.push(8);
    return items;
  }, [floor]);

  const onPrevClick = (): void => {
    setCurrentSlide(currentSlide - 1);
  };

  const onNextClick = (): void => {
    setCurrentSlide(currentSlide + 1);
  };

  return (
    <div id="interior_canvas" className="canvas interior_canvas">
      <div className="slider-wrapper">
        <ul className="slider" style={{marginLeft: currentSlide === 0 ? '0' : `-${100 * currentSlide}%`}}>
          <InteriorImageList large={large} />
        </ul>
      </div>
      <div className="navigation-wrapper">
        <div className="navigation">
          <button className="prev" onClick={onPrevClick} disabled={currentSlide === 0}>
            <Icon icon={Icons.ArrowLeft} />
          </button>
          <button className="next" onClick={onNextClick} disabled={currentSlide === (pagination.length - 1)}>
            <Icon icon={Icons.ArrowRight} />
          </button>
        </div>
      </div>
      <div className="pagination-wrapper">
        <ul className="pagination">
          {pagination.map((index: number) => {
            return (
              <li className={`item ${currentSlide === (index - 1) ? '-active' : ''}`} key={index}></li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
