import './TabMenu.sass';

export interface TabMenuItem {
  label: string;
  value: any;
}

interface TabMenuProps {
  items: TabMenuItem[];
  selectedItem: any;
  onTabClick: (value: any) => void;
  className?: string;
}

export const TabMenu: React.FC<TabMenuProps> = ({ items, selectedItem, onTabClick, className }) => {
  return (
    <ul id="tab_menu" className={className}>
      {items.map((item: TabMenuItem) => {
        return (
          <li className={`item ${selectedItem === item.value ? '-active' : ''}`} key={item.value}>
            <button className="tab" onClick={() => {onTabClick(item.value);}}>
              {item.label}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
