import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { DefaultButton } from 'components/partial/Button/DefaultButton/DefaultButton';
import { ReactComponent as BuddyBlue } from 'assets/images/buddy-blue.svg';
import './SuccessMessage.sass';

export const SuccessMessage: React.FC = () => {
  const { t } = useTranslation();
  const { setStep, setIsSubmitted } = useConfigurator();

  const email = 'nicklas.hagervall@skanska.se';

  const onRestartClick = (): void => {
    setStep(ConfiguratorSteps.Concept);
    setIsSubmitted(false);
  };

  return (
    <div id="success_message">
      <div className="buddy-wrapper">
        <BuddyBlue />
      </div>
      <div className="title-wrapper">
        <h2 className="title">
          {t('pages.configuratorPage.views.overviewView.successMessage.title.0')}
          <br />
          {t('pages.configuratorPage.views.overviewView.successMessage.title.1')}
        </h2>
      </div>
      <div className="text-wrapper">
        <p className="paragraph" dangerouslySetInnerHTML={{__html: t('pages.configuratorPage.views.overviewView.successMessage.text', { email: `<a href="mailto:${email}" class="hyperlink">${email}</a>` })}}></p>
      </div>
      <div className="button-wrapper">
        <DefaultButton
          label={t('pages.configuratorPage.views.overviewView.successMessage.buildAnother')}
          onClick={onRestartClick}
        />
      </div>
    </div>
  );
};
