import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { Page } from 'components/partial/Page/Page';
import { ConceptView } from './ConceptView/ConceptView';
import { ExteriorView } from './ExteriorView/ExteriorView';
import { InteriorView } from './InteriorView/InteriorView';
import { OverviewView } from './OverviewView/OverviewView';

export const ConfiguratorPage: React.FC = () => {
  const { step } = useConfigurator();

  return (
    <Page id="configurator_page">
      {step === ConfiguratorSteps.Concept ? <ConceptView /> : null}
      {step === ConfiguratorSteps.Exterior ? <ExteriorView /> : null}
      {step === ConfiguratorSteps.Interior ? <InteriorView /> : null}
      {step === ConfiguratorSteps.Overview ? <OverviewView /> : null}
    </Page>
  );
};
