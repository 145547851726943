import {useTranslation} from 'react-i18next';
import {BetterChoiceBadge} from 'components/core/Badge/BetterChoiceBadge/BetterChoiceBadge';
import {SquareButton, SquareButtonStates} from 'components/partial/Button/SquareButton/SquareButton';
import {Icon, Icons} from 'components/partial/Icon/Icon';
import './PreviewCanvasInfo.sass';

interface PreviewCanvasInfoProps {
  title: string;
  text: string;
  note?: string;
  isOpen: boolean;
  onClick: () => void;
}

export const PreviewCanvasInfo: React.FC<PreviewCanvasInfoProps> = ({ title, text, note, isOpen, onClick }) => {
  const { t } = useTranslation();

  if (isOpen) {
    return (
      <div id="preview_canvas_info" className="-open">
        <div className="content-wrapper">
          <div className="content">
            <div className="inner-wrapper">
              <div className="inner">
                <div className="title-wrapper">
                  <h5 className="title">{title}</h5>
                </div>
                <div className="text-wrapper">
                  <p className="paragraph">{text}</p>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <SquareButton
                label={t('common.close')}
                icon={Icons.Close}
                states={[SquareButtonStates.Transparent, SquareButtonStates.Auto, SquareButtonStates.Large]}
                onClick={onClick}
              />
            </div>
          </div>
        </div>
        {note && <div className="note-wrapper">
          <div className="note">
            <div className="badge-wrapper">
              <BetterChoiceBadge />
            </div>
            <div className="paragraph-wrapper">
              <p className="paragraph">{note}</p>
            </div>
          </div>
        </div>}
      </div>
    );
  }

  return (
    <button id="preview_canvas_info" className="-closed" onClick={onClick}>
      <Icon icon={Icons.Info} />
    </button>
  );
};
