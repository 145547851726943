import { useTranslation } from 'react-i18next';
import { SummaryItem } from 'components/pages/ConfiguratorPage/Summary/SummaryItem/SummaryItem';

export const DescriptionSummaryItem: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SummaryItem>
      <p className="paragraph">
        {t('pages.configuratorPage.summary.description.0')}
      </p>
      <p className="paragraph">
        {t('pages.configuratorPage.summary.description.1')}
      </p>
      {/*<p className="paragraph">
        {t('pages.configuratorPage.summary.description.2')}
      </p>*/}
      <p className="paragraph">
        {t('pages.configuratorPage.summary.description.3')}
      </p>
      <p className="paragraph">
        {t('pages.configuratorPage.summary.description.4')}
      </p>
    </SummaryItem>
  );
};
