import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { Category, CategoryItem } from 'interfaces/Configurator';
import { SummaryItem } from 'components/pages/ConfiguratorPage/Summary/SummaryItem/SummaryItem';
import { SummaryItemTable, SummaryItemTableItem, SummaryItemTableItemValues } from 'components/pages/ConfiguratorPage/Summary/SummaryItem/SummaryItemTable/SummaryItemTable';

export const InteriorSummaryItem: React.FC = () => {
  const { t } = useTranslation();
  const { config, concept, configOptions } = useConfigurator();

  const interior = useMemo((): SummaryItemTableItem[] => {
    if (!concept) return [];
    return configOptions.interior.map((category: Category) => {
      let values: SummaryItemTableItemValues[] = [];
      const selectedOption = category.options.filter((option: CategoryItem) => option.concepts.indexOf(concept.key) > -1).find((option: CategoryItem) => option.id === config.interior.selectedOptions[category.key].selectedOption);
      const selectedAddons = category.addons.filter((option: CategoryItem) => option.concepts.indexOf(concept.key) > -1).filter((addon: CategoryItem) => config.interior.selectedOptions[category.key].selectedAddons.indexOf(addon.id) > -1);
      if (selectedOption) {
        const label = t(`categories.interior.${category.key}.options.${selectedOption.id}.label`);
        const details = t(`categories.interior.${category.key}.options.${selectedOption.id}.details`);
        values.push({
          text: `${label} (${details})`,
          cost: `${selectedOption.cost[concept.key].toLocaleString()} ${t('common.units')}`,
        });
      }
      if (selectedAddons.length) {
        const addonValues = selectedAddons.map((addon: CategoryItem) => {
          const label = t(`categories.interior.${category.key}.options.${addon.id}.label`);
          const details = t(`categories.interior.${category.key}.options.${addon.id}.details`);
          return {
            text: `${label} (${details})`,
            cost: `${addon.cost[concept.key].toLocaleString()} ${t('common.units')}`,
          };
        });
        values = [...values, ...addonValues];
      }
      return {
        label: t(`categories.interior.${category.key}.title`),
        values: values,
      };
    });
  }, [configOptions, config, concept, t]);

  return (
    <SummaryItem title={t('pages.configuratorPage.summary.interiorSelections')}>
      <SummaryItemTable items={interior} />
    </SummaryItem>
  );
};
