import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { BiteriorViewRightWrapper } from 'components/pages/ConfiguratorPage/BiteriorView/BiteriorViewRightWrapper/BiteriorViewRightWrapper';
import { Configuration } from 'components/pages/ConfiguratorPage/Configuration/Configuration';
import { InteriorViewDropdownList } from './InteriorViewDropdownList/InteriorViewDropdownList';
import { TabMenuItem } from 'components/partial/TabMenu/TabMenu';

enum InteriorViewTabMenuValues {
  Interior,
  Summary,
}

export const InteriorViewRightWrapper: React.FC = () => {
  const { t } = useTranslation();
  const { setStep } = useConfigurator();

  const [currentTab, setCurrentTab] = useState<InteriorViewTabMenuValues>(InteriorViewTabMenuValues.Interior);

  const tabs: TabMenuItem[] = [
    { label: t('common.selectInterior'), value: InteriorViewTabMenuValues.Interior },
    { label: t('common.showSummary'), value: InteriorViewTabMenuValues.Summary },
  ];

  return (
    <BiteriorViewRightWrapper
      tabs={tabs}
      currentTab={currentTab}
      showSummary={currentTab === InteriorViewTabMenuValues.Summary}
      onTabClick={setCurrentTab}
      onNextClick={() => {setStep(ConfiguratorSteps.Overview);}}
      className="interior_view_right_wrapper"
    >
      <Configuration
        title={t('common.selectInterior')}
        text={t('pages.configuratorPage.views.interiorView.text')}
        className="interior_view_configuration"
      >
        <InteriorViewDropdownList />
      </Configuration>
    </BiteriorViewRightWrapper>
  );
};
