import { createContext, useContext, useState } from 'react';

interface ModalsContextProps {
  isContactOpen: boolean;
  openContact: () => void;
  closeContact: () => void;
  isHelpOpen: boolean;
  openHelp: () => void;
  closeHelp: () => void;
  isSmallScreenOpen: boolean;
  openSmallScreen: () => void;
  closeSmallScreen: () => void;
}

const ModalsContext = createContext<ModalsContextProps>({
  isContactOpen: false,
  openContact: () => {},
  closeContact: () => {},
  isHelpOpen: false,
  openHelp: () => {},
  closeHelp: () => {},
  isSmallScreenOpen: false,
  openSmallScreen: () => {},
  closeSmallScreen: () => {},
});

export const ProvideModals: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const data = useProvideModals();
  return <ModalsContext.Provider value={data}>{children}</ModalsContext.Provider>;
};

export const useModals = (): ModalsContextProps => useContext(ModalsContext);

const useProvideModals = (): ModalsContextProps => {
  const [isContactOpen, setIsContactOpen] = useState<boolean>(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [isSmallScreenOpen, setIsSmallScreenOpen] = useState<boolean>(false);

  const openContact = (): void => {
    setIsContactOpen(true);
  };

  const closeContact = (): void => {
    setIsContactOpen(false);
  };

  const openHelp = (): void => {
    setIsHelpOpen(true);
  };

  const closeHelp = (): void => {
    setIsHelpOpen(false);
  };

  const openSmallScreen = (): void => {
    setIsSmallScreenOpen(true);
  };

  const closeSmallScreen = (): void => {
    setIsSmallScreenOpen(false);
  };

  return {
    isContactOpen,
    openContact,
    closeContact,
    isHelpOpen,
    openHelp,
    closeHelp,
    isSmallScreenOpen,
    openSmallScreen,
    closeSmallScreen,
  };
};
