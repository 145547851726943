import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { useCalculator } from 'hooks/useCalculator';
import { DefaultButton } from 'components/partial/Button/DefaultButton/DefaultButton';
import { Icons } from 'components/partial/Icon/Icon';
import './SubmitForm.sass';

export const SubmitForm: React.FC = () => {
  const { t } = useTranslation();
  const { onSubmit } = useConfigurator();
  const { estimatedTotalCost, costPerBTAExcl, costPerBTAIncl, rentalCharge, annualCharge, primaryEnergy, carbonFootprint } = useCalculator();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string | null>(null);
  const [contacted, setContacted] = useState<boolean | null>(null);
  const [consent, setConsent] = useState<boolean>(false);

  const onNameChange = (event: React.BaseSyntheticEvent): void => {
    setName(event.target.value);
  };

  const onEmailChange = (event: React.BaseSyntheticEvent): void => {
    const isValid = /\S+@\S+\.\S+/.test(event.target.value);
    if (isValid) {
      setEmail(event.target.value);
    } else {
      setEmail(null);
    }
  };

  const onSubmitClick = (): void => {
    if (!email || contacted === null) return;
    onSubmit(email, name, {
      estimatedTotalCost,
      costPerBTAExcl,
      costPerBTAIncl,
      rentalCharge,
      annualCharge,
      primaryEnergy,
      carbonFootprint,
    }, contacted);
  };

  return (
    <div id="submit_form">
      <div className="title-wrapper">
        <h2 className="title">{t('pages.configuratorPage.views.overviewView.submitForm.title')}</h2>
      </div>
      <div className="text-wrapper">
        <p className="paragraph">{t('pages.configuratorPage.views.overviewView.submitForm.text')}</p>
      </div>
      <div className="form-wrapper">
        <div id="form">
          <div className="field-wrapper">
            <label className="field">
              <div className="label-wrapper">
                <span className="label">{t('pages.configuratorPage.views.overviewView.submitForm.fields.name.label')}</span>
              </div>
              <div className="input-wrapper">
                <input type="text" className="input -text" onChange={onNameChange} />
              </div>
            </label>
          </div>
          <div className="field-wrapper">
            <label className="field">
              <div className="label-wrapper">
                <span className="label">{t('pages.configuratorPage.views.overviewView.submitForm.fields.email.label')}</span>
              </div>
              <div className="input-wrapper">
                <input type="email" className="input -text" onChange={onEmailChange} />
              </div>
            </label>
          </div>
          <div className="field-wrapper">
            <div className="field">
              <div className="label-wrapper">
                <span className="label">{t('pages.configuratorPage.views.overviewView.submitForm.fields.contact.label')}</span>
              </div>
              <div className="input-wrapper">
                <div className="input -radio">
                  <div className="item-wrapper">
                    <button className="item" onClick={() => {setContacted(true);}}>
                      <div className="input-wrapper">
                        <span className={`input ${contacted ? '-selected' : ''}`}></span>
                      </div>
                      <div className="label-wrapper">
                        <span className="label">{t('pages.configuratorPage.views.overviewView.submitForm.fields.contact.options.0')}</span>
                      </div>
                    </button>
                  </div>
                  <div className="item-wrapper">
                    <button className="item" onClick={() => {setContacted(false);}}>
                      <div className="input-wrapper">
                        <span className={`input ${contacted === false ? '-selected' : ''}`}></span>
                      </div>
                      <div className="label-wrapper">
                        <span className="label">{t('pages.configuratorPage.views.overviewView.submitForm.fields.contact.options.1')}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="consent-wrapper">
            <button className="consent" onClick={() => {setConsent(!consent);}}>
              <div className="input-wrapper">
                <span className={`input ${consent ? '-selected' : ''}`}></span>
              </div>
              <div className="label-wrapper">
                <span className="label">{t('pages.configuratorPage.views.overviewView.submitForm.consent')}</span>
              </div>
            </button>
          </div>
          <div className="button-wrapper">
            <DefaultButton
              label={t('common.send')}
              icon={Icons.ArrowRight}
              onClick={onSubmitClick}
              disabled={email === null || contacted === null || !consent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
