import { BetterChoiceBadge } from 'components/core/Badge/BetterChoiceBadge/BetterChoiceBadge';
import './DropdownRadioItem.sass';

interface DropdownRadioItemProps {
  label: string;
  cost: string;
  betterChoice: boolean;
  details: string;
  selected: boolean;
  item: any;
  onClick: (value: any) => void;
  imageUrl?: string;
}

export const DropdownRadioItem: React.FC<DropdownRadioItemProps> = ({ label, cost, betterChoice, details, selected, item, onClick, imageUrl }) => {
  if (item.visualize) {
    return (
      <button
        className={`dropdown_radio_item -image ${selected ? '-selected' : ''}`}
        onClick={() => {onClick(item);}}
      >
        <div className="image-wrapper">
          <img src={imageUrl} alt="" className="image" />
        </div>
        <div className="content-wrapper">
          <div className="content">
            <div className="label-wrapper">
              <h6 className="label">{label}</h6>
              <span className="cost">(+ {cost} kr)</span>
              {betterChoice && <BetterChoiceBadge />}
            </div>
            <div className="detail-wrapper">
              <span className="detail" dangerouslySetInnerHTML={{__html: details.replace('\n', '<br />')}}></span>
            </div>
          </div>
        </div>
        <div className="input-wrapper">
          <span className={`input ${selected ? '-selected' : ''}`}></span>
        </div>
      </button>
    );
  }

  return (
    <button
      className="dropdown_radio_item -default"
      onClick={() => {onClick(item);}}
    >
      <div className="input-wrapper">
        <span className={`input ${selected ? '-selected' : ''}`}></span>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <div className="label-wrapper">
            <h6 className="label">{label}</h6>
            <span className="cost">(+ {cost} kr)</span>
            {betterChoice && <BetterChoiceBadge />}
          </div>
          <div className="detail-wrapper">
            <span className="detail">{details}</span>
          </div>
        </div>
      </div>
    </button>
  );
};
