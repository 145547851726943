import { useTranslation } from 'react-i18next';
import { useAlert } from 'hooks/useAlert';
import { Icon, Icons } from 'components/partial/Icon/Icon';
import { DefaultButton } from 'components/partial/Button/DefaultButton/DefaultButton';
import './Alert.sass';

export const Alert: React.FC = () => {
  const { t } = useTranslation();
  const { hideAlert } = useAlert();

  return (
    <div id="alert">
      <div className="box">
        <div className="icon-wrapper">
          <Icon icon={Icons.Info} />
        </div>
        <div className="message-wrapper">
          <p className="message">
            {t('alert.somethingWentWrong.0')}<br />{t('alert.somethingWentWrong.1')}
          </p>
        </div>
        <div className="button-wrapper">
          <DefaultButton
            label={t('common.ok')}
            onClick={hideAlert}
          />
        </div>
      </div>
    </div>
  );
};
