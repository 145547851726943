const request = async <T>(method: string, url: string, body?: any, headers?: any): Promise<T> => {
  const response = await fetch(url, {
    method,
    body: body ? body : null,
    headers: headers ? headers : {}
  });

  if (response.status > 299) {
    throw Error();
  }

  try {
    const json = await response.json();
    return json as T;
  } catch (e: any) {
    return {} as T;
  }
};

export const get = async <T>(url: string): Promise<T> => {
  return await request<T>('GET', url);
};

export const post = async <T>(url: string, body?: any, headers?: any): Promise<T> => {
  return await request<T>('POST', url, body, headers);
};
