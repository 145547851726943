import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { useCalculator } from 'hooks/useCalculator';
import './PreviewRatio.sass';

export const PreviewRatio: React.FC = () => {
  const { t } = useTranslation();
  const { concept } = useConfigurator();
  const { carbonFootprint, rentalCharge, estimatedTotalCost } = useCalculator();

  if (!concept) {
    return null;
  }
  
  return (
    <div id="preview_ratio">
      <div className="ratio">
        <div className="concept-wrapper">
          <div className="concept">
            <div className="label-wrapper">
              <h5 className="label">{t(`concepts.${concept.key}.title`)}</h5>
            </div>
            <div className="detail-wrapper">
              <span className="detail">{t('common.currentRatio')}</span>
            </div>
          </div>
        </div>
        <div className="ratio-wrapper">
          <div className="ratio">
            <div className="carbon_footprint-wrapper">
              <div className="carbon_footprint">
                <div className="label-wrapper">
                  <h6 className="label">{t('common.carbonFootprint')}</h6>
                </div>
                <div className="data-wrapper">
                  <div className="data">
                    <div className="value-wrapper">
                      <span className="value">{carbonFootprint}</span>
                    </div>
                    <div className="suffix-wrapper">
                      <span className="suffix">{t('common.kilogramsCO2ePerSquareMeters')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="rental_charge-wrapper">
              <div className="rental_charge">
                <div className="label-wrapper">
                  <h6 className="label">{t('common.rentalCharge')}*</h6>
                </div>
                <div className="data-wrapper">
                  <div className="data">
                    <div className="value-wrapper">
                      <span className="value">{rentalCharge}</span>
                    </div>
                    <div className="suffix-wrapper">
                      <span className="suffix">{t('common.unitsPerSquareMetersBRAPerYear')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className="estimated_total_cost-wrapper">
              <div className="estimated_total_cost">
                <div className="label-wrapper">
                  <h6 className="label">{t('common.estimatedTotalCost')}*</h6>
                </div>
                <div className="data-wrapper">
                  <div className="data">
                    <div className="value-wrapper">
                      <span className="value">{estimatedTotalCost}</span>
                    </div>
                    <div className="suffix-wrapper">
                      <span className="suffix">{t('common.millionUnits')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="note">
        <p className="paragraph">*{t('common.previewNote')}</p>
      </div>
    </div>
  );
};
