import './RightWrapper.sass';

interface RightWrapperProps extends React.PropsWithChildren {
  reference?: React.RefObject<HTMLDivElement>;
  className?: string;
}

export const RightWrapper: React.FC<RightWrapperProps> = ({ children, reference, className }) => {
  return (
    <div id="right_wrapper" className={className} ref={reference}>
      {children}
    </div>
  );
};
