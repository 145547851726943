import { createContext, useContext, useState } from 'react';

interface AlertContextProps {
  isAlertOpen: boolean;
  showAlert: () => void;
  hideAlert: () => void;
}

const AlertContext = createContext<AlertContextProps>({
  isAlertOpen: false,
  showAlert: () => {},
  hideAlert: () => {},
});

export const ProvideAlert: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const data = useProvideAlert();
  return <AlertContext.Provider value={data}>{children}</AlertContext.Provider>;
};

export const useAlert = (): AlertContextProps => useContext(AlertContext);

const useProvideAlert = (): AlertContextProps => {
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const showAlert = (): void => {
    setIsAlertOpen(true);
  };

  const hideAlert = (): void => {
    setIsAlertOpen(false);
  };

  return {
    isAlertOpen,
    showAlert,
    hideAlert,
  };
};
