import { Link } from 'react-router-dom';
import { Icon, Icons } from 'components/partial/Icon/Icon';
import './Anchor.sass';

export interface AnchorProps {
  label: string;
  onClick?: () => void;
  href?: string;
  icon?: Icons;
  native?: boolean;
  target?: string;
  className?: string
}

export const Anchor: React.FC<AnchorProps> = ({ label, onClick, href, icon, native = false, target = '', className = '' }) => {
  if (native) {
    return (
      <a
        className={`anchor ${className}`}
        href={href}
        target={target}
      >
        {icon && <Icon icon={icon} />}
        <span className="label">{label}</span>
      </a>
    );
  }

  if (href) {
    return (
      <Link
        className={`anchor ${className}`}
        to={href}
      >
        {icon && <Icon icon={icon} />}
        <span className="label">{label}</span>
      </Link>
    );
  }

  return (
    <button
      className={`anchor ${className}`}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} />}
      <span className="label">{label}</span>
    </button>
  );
};
