import { BiteriorView } from '../BiteriorView/BiteriorView';
import { InteriorViewLeftWrapper } from './InteriorViewLeftWrapper/InteriorViewLeftWrapper';
import { InteriorViewRightWrapper } from './InteriorViewRightWrapper/InteriorViewRightWrapper';

export const InteriorView: React.FC = () => {
  return (
    <BiteriorView name="interior">
      <InteriorViewLeftWrapper />
      <InteriorViewRightWrapper />
    </BiteriorView>
  );
};
