import Kompis from 'assets/images/kompis.gif';
import './Loader.sass';

export const Loader: React.FC = () => {
  return (
    <div id="loader">
      <img src={Kompis} alt="" className="image" />
    </div>
  );
};
