export interface Configurator {
  exterior: Biterior;
  interior: Biterior;
}

export type Biterior = {
  selectedOptions: {
    [category: string]: {
      selectedOption: number | null;
      selectedAddons: number[];
    };
  }
}

export enum ConfiguratorSteps {
  Concept,
  Exterior,
  Interior,
  Overview,
}

export enum Concepts {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export interface Concept {
  id: number;
  key: Concepts;
  bta: number;
  loa: number;
  cost: number;
  co2: number;
  kwh: number;
  rent: number;
  departments: number;
  constructionTime: 10;
  childrenArea: number;
  groundwork: {
    cost: number;
    rent: number;
  };
}

export type ConceptCollection = {
  [concept: string]: Concept;
}

export interface Category {
  id: number;
  key: string;
  options: CategoryItem[];
  addons: CategoryItem[];
}

export interface CategoryItem extends CategoryItemTypeProps {
  id: number;
  concepts: Concepts[];
  recommended: boolean;
  visualize: boolean;
}

export interface CategoryItemTypeProps {
  cost: {[concept: string]: number;};
  co2: {[concept: string]: number;};
  kwh: {[concept: string]: number;};
  rent: {[concept: string]: number;};
}

export interface ChildGroup {
  label: string;
  value: ChildGroupValues;
}

export enum ChildGroupValues {
  Small,
  Medium,
  Large,
}

export interface Floor {
  label: string;
  value: FloorValues;
}

export enum FloorValues {
  One = 1,
  Two = 2,
}

export interface ConfiguratorOptions {
  exterior: Category[];
  interior: Category[];
}

export interface CalculationValues {
  estimatedTotalCost: number;
  costPerBTAExcl: number;
  costPerBTAIncl: number;
  rentalCharge: number;
  annualCharge: number;
  primaryEnergy: number;
  carbonFootprint: number;
}
