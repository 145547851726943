import './OptionList.sass';

export interface OptionListItem {
  label: string;
  value: any;
  disabled?: boolean;
  note?: {
    text: string;
    visible: boolean;
  };
}

interface OptionListProps {
  title: string;
  options: OptionListItem[];
  currentValue: any;
  className: string;
  onItemChange: (value: any) => void;
}

export const OptionList: React.FC<OptionListProps> = ({ title, options, currentValue, className, onItemChange }) => {
  return (
    <div className="option_list">
      <div className="title-wrapper">
        <h3 className="title">{title}</h3>
      </div>
      <div className="list-wrapper">
        <ul className="list">
          {options.map((item: OptionListItem) => {
            return (
              <li className="item" key={item.value}>
                <button className="option" onClick={() => {onItemChange(item.value);}} disabled={item.disabled}>
                  <div className="checkbox-wrapper">
                    <span className={`checkbox ${item.value === currentValue ? '-selected' : ''}`}></span>
                  </div>
                  <div className="label-wrapper">
                    <span className="label">
                      {item.label}
                    </span>
                    {item.note && <span className={`note ${item.note.visible ? '-visible' : ''}`}>{item.note.text}</span>}
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
