import './SummaryItemTable.sass';

export interface SummaryItemTableItemValues {
  text: string;
  cost: string;
  bold?: boolean;
}

export interface SummaryItemTableItem {
  label: string | undefined;
  values: SummaryItemTableItemValues[];
}

interface SummaryItemTableProps {
  items: SummaryItemTableItem[];
}

export const SummaryItemTable: React.FC<SummaryItemTableProps> = ({ items }) => {
  return (
    <div className="summary_item_table">
      {items.map((item: SummaryItemTableItem, index: number) => {
        return (
          <div className="block-wrapper" key={index}>
            <div className="block">
              {item.label && <div className="title-wrapper">
                <h4 className="title">{item.label}</h4>
              </div>}
              <div className="selection_list-wrapper">
                <div className="selection_list">
                  {item.values.map((value: SummaryItemTableItemValues, vIndex: number) => {
                    return (
                      <div className="item" key={vIndex}>
                        <span className={`value ${value.bold ? '-bold' : ''}`}>{value.text}</span>
                        <span className="cost">{value.cost}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
