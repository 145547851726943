import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks/useModals';
import { Modal } from 'components/partial/Modal/Modal';
import { SquareButton } from 'components/partial/Button/SquareButton/SquareButton';
import { Icons } from 'components/partial/Icon/Icon';
import './ContactModal.sass';

export const ContactModal: React.FC  = () => {
  const { t } = useTranslation();
  const { closeContact } = useModals();

  return (
    <Modal onBackdropClick={closeContact} className="contact_modal">
      <div className="header-wrapper">
        <div className="header">
          <div className="title-wrapper">
            <h1 className="title">
              {t('modals.contact.title.0')}<br />
              {t('modals.contact.title.1')}
            </h1>
          </div>
          <div className="button-wrapper">
            <SquareButton
              label={t('common.close')}
              icon={Icons.Close}
              onClick={closeContact}
            />
          </div>
        </div>
      </div>

      <div className='information-wrapper'>
        <div className="information">
          <div className="image-wrapper">
            <img src={'/images/general/nicklas.png'} alt="" className='image'/>
          </div>
          <div className='text-wrapper'>
            <p className="text">
              <span className='label'>{t('modals.contact.contact.name')}</span>
              <span className='label'>{t('modals.contact.contact.role')}</span>
              <a href={`tel:${t('modals.contact.contact.phone')}`} className='label -hyperlink'>{t('modals.contact.contact.phone')}</a>
              <a href={`mailto:${t('modals.contact.contact.email')}`} className='label -hyperlink'>{t('modals.contact.contact.email')}</a>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
