import { useTranslation } from 'react-i18next';
import { Badge } from 'components/partial/Badge/Badge';

export const BetterChoiceBadge: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Badge label={t('common.betterChoice')} className="better_choice_badge" />
  );
};
