import { PreviewCanvasOverlay } from './PreviewCanvasOverlay/PreviewCanvasOverlay';
import './PreviewCanvas.sass';

interface PreviewCanvasProps extends React.PropsWithChildren {
  information: {
    title: string;
    text: string;
    note?: string;
  },
  onFullScreenClick: () => void;
  onLayoutClick: () => void;
  className?: string;
}

export const PreviewCanvas: React.FC<PreviewCanvasProps> = ({ children, information, onFullScreenClick, onLayoutClick, className }) => {
  return (
    <div id="preview_canvas" className={className}>
      <div className="canvas-wrapper">
        <PreviewCanvasOverlay
          information={information}
          onFullScreenClick={onFullScreenClick}
          onLayoutClick={onLayoutClick}
        />
        {children}
      </div>
    </div>
  );
};
