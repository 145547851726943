import './Modal.sass';

interface ModalProps extends React.PropsWithChildren{
  onBackdropClick?: () => void;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({children, onBackdropClick, className=''}) => {
  return (
    <div className={`modal ${className}`}>
      <div className="backdrop" onClick={onBackdropClick}></div>
      <div className="box">
        {children}
      </div>
    </div>
  );
};
