import { PreviewRatio } from './PreviewRatio/PreviewRatio';
import './Preview.sass';

interface PreviewProps extends React.PropsWithChildren {
  className?: string;
}

export const Preview: React.FC<PreviewProps> = ({ children, className = ''}) => {
  return (
    <div id="preview" className={className}>
      {children}
      <PreviewRatio />
    </div>
  );
};
