import { useTranslation } from 'react-i18next';
import { useModals } from 'hooks/useModals';
import { Modal } from 'components/partial/Modal/Modal';
import { SquareButton, SquareButtonStates } from 'components/partial/Button/SquareButton/SquareButton';
import { Anchor } from 'components/partial/Anchor/Anchor';
import { Icons } from 'components/partial/Icon/Icon';
import './HelpModal.sass';

export const HelpModal: React.FC  = () => {
  const { t } = useTranslation();
  const { closeHelp } = useModals();

  return(
    <Modal onBackdropClick={closeHelp} className="help_modal">
      <div className="button-wrapper">
        <SquareButton
          label={t('common.close')}
          icon={Icons.Close}
          states={[SquareButtonStates.Transparent, SquareButtonStates.Large]}
          onClick={closeHelp}
        />
      </div>
      <div className="content-wrapper">
        <div className="content">
          <div className="primary_content-wrapper">
            <div className="primary_content">
              <div className="title-wrapper">
                <h1 className="title">{t('modals.help.title')}</h1>
              </div>
              <div className="content-wrapper">
                <div className="content">
                  <div className="section-wrapper">
                    <div className="section">
                      <div className="title-wrapper">
                        <h2 className="title">{t('modals.help.primaryContent.0.title')}</h2>
                      </div>
                      <div className="text-wrapper">
                        <p className="paragraph">{t('modals.help.primaryContent.0.text')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="section-wrapper">
                    <div className="section">
                      <div className="title-wrapper">
                        <h2 className="title">{t('modals.help.primaryContent.1.title')}</h2>
                      </div>
                      <div className="text-wrapper">
                        <p className="paragraph">{t('modals.help.primaryContent.1.text.0')}</p>
                        <p className="paragraph">{t('modals.help.primaryContent.1.text.1')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="link-wrapper">
                <Anchor
                  label={t('modals.help.readMore.label')}
                  href={t('modals.help.readMore.link')}
                  icon={Icons.ExternalLink}
                  native={true}
                  target="_blank"
                />
              </div>
            </div>
          </div>
          <div className="secondary_content-wrapper">
            <div className="secondary_content">
              <div className="section-wrapper">
                <div className="section">
                  <div className="title-wrapper">
                    <h3 className="title">{t('modals.help.secondaryContent.0.title')}</h3>
                  </div>
                  <div className="text-wrapper">
                    <p className="paragraph">{t('modals.help.secondaryContent.0.text.0')}</p>
                    <p className="paragraph">{t('modals.help.secondaryContent.0.text.1')}</p>
                  </div>
                </div>
              </div>
              <div className="section-wrapper">
                <div className="section">
                  <div className="title-wrapper">
                    <h3 className="title">{t('modals.help.secondaryContent.1.title')}</h3>
                  </div>
                  <div className="text-wrapper">
                    <p className="paragraph">{t('modals.help.secondaryContent.1.text')}</p>
                  </div>
                </div>
              </div>
              <div className="section-wrapper">
                <div className="section">
                  <div className="title-wrapper">
                    <h3 className="title">{t('modals.help.secondaryContent.2.title')}</h3>
                  </div>
                  <div className="text-wrapper">
                    <p className="paragraph">{t('modals.help.secondaryContent.2.text.0')}</p>
                    <p className="paragraph">{t('modals.help.secondaryContent.2.text.1')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
