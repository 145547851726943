import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { BiteriorViewRightWrapper } from 'components/pages/ConfiguratorPage/BiteriorView/BiteriorViewRightWrapper/BiteriorViewRightWrapper';
import { Configuration } from 'components/pages/ConfiguratorPage/Configuration/Configuration';
import { ExteriorViewDropdownList } from './ExteriorViewDropdownList/ExteriorViewDropdownList';
import { OutdoorEnvironmentDropdown } from './OutdoorEnvironmentDropdown/OutdoorEnvironmentDropdown';
import { TabMenuItem } from 'components/partial/TabMenu/TabMenu';

enum ExteriorViewTabMenuValues {
  Exterior,
  Summary,
}

export const ExteriorViewRightWrapper: React.FC = () => {
  const { t } = useTranslation();
  const { setStep } = useConfigurator();

  const [currentTab, setCurrentTab] = useState<ExteriorViewTabMenuValues>(ExteriorViewTabMenuValues.Exterior);

  const tabs: TabMenuItem[] = [
    { label: t('common.selectExterior'), value: ExteriorViewTabMenuValues.Exterior },
    { label: t('common.showSummary'), value: ExteriorViewTabMenuValues.Summary },
  ];

  return (
    <BiteriorViewRightWrapper
      tabs={tabs}
      currentTab={currentTab}
      showSummary={currentTab === ExteriorViewTabMenuValues.Summary}
      onTabClick={setCurrentTab}
      onNextClick={() => {setStep(ConfiguratorSteps.Interior);}}
      className="exterior_view_right_wrapper"
    >
      <Configuration
        title={t('common.selectExterior')}
        text={t('pages.configuratorPage.views.exteriorView.text')}
        note={t('pages.configuratorPage.views.exteriorView.note')}
        className="exterior_view_configuration"
      >
        <ExteriorViewDropdownList />
        <OutdoorEnvironmentDropdown />
      </Configuration>
    </BiteriorViewRightWrapper>
  );
};
