import { useTranslation } from 'react-i18next';
import { Modal } from 'components/partial/Modal/Modal';
import { Icon, Icons } from 'components/partial/Icon/Icon';
import './SmallScreenModal.sass';

export const SmallScreenModal: React.FC  = () => {
  const { t } = useTranslation();

  return(
    <Modal className="small_screen_modal">
      <div className="icon-wrapper">
        <Icon icon={Icons.Eye} />
      </div>
      <div className="text-wrapper -one">
        <p className="paragraph">
          {t('modals.smallScreen.text.0')}
        </p>
      </div>
      <div className="text-wrapper -two">
        <p className="paragraph">
          <b>{t('modals.smallScreen.text.1')}</b><br />
          {t('modals.smallScreen.text.2')}
        </p>
      </div>
      <div className="text-wrapper -three">
        <p className="paragraph">
          <b>{t('modals.smallScreen.text.3')}</b><br />
          {t('modals.smallScreen.text.4')}
        </p>
      </div>
    </Modal>
  );
};
