import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useModals } from './hooks/useModals';
import { useLoader } from './hooks/useLoader';
import { useAlert } from './hooks/useAlert';
import { initi18n } from './helpers/LocaleHelpers';
import { Header } from './components/core/Header/Header';
import { ConfiguratorPage } from './components/pages/ConfiguratorPage/ConfiguratorPage';
import { ContactModal } from 'components/core/Modal/ContactModal/ContactModal';
import { HelpModal } from 'components/core/Modal/HelpModal/HelpModal';
import { SmallScreenModal } from 'components/core/Modal/SmallScreenModal/SmallScreenModal';
import { Loader } from './components/partial/Loader/Loader';
import { Alert } from './components/partial/Alert/Alert';

initi18n();

const App: React.FC = () => {
  const { isContactOpen, isHelpOpen } = useModals();
  const { isLoaderOpen } = useLoader();
  const { isAlertOpen } = useAlert();
  
  return (
    <BrowserRouter>
      {window.innerWidth < 1024 ? (
        <SmallScreenModal />
      ) : (
        <>
          <Header />
          <main id="main">
            <Routes>
              <Route path="/" element={ <ConfiguratorPage /> } />
            </Routes>
          </main>
          { isContactOpen && <ContactModal /> }
          { isHelpOpen && <HelpModal /> }
          { isLoaderOpen && <Loader /> }
          { isAlertOpen && <Alert /> }
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
