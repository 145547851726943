import { DefaultButton, DefaultButtonStates } from 'components/partial/Button/DefaultButton/DefaultButton';
import { Icons } from 'components/partial/Icon/Icon';
import { useModals } from 'hooks/useModals';
import './HeaderMenu.sass';

interface iHeaderMenuItem {
  label: string;
  icon: Icons;
  onClick: () => void;
}

export const HeaderMenu: React.FC = () => {
  const { openContact, openHelp } = useModals();

  const menuItems: iHeaderMenuItem[] = [
    { label: 'Kontakta oss', icon: Icons.Contact, onClick: openContact },
    { label: 'Hjälp', icon: Icons.Help, onClick: openHelp },
  ];

  return (
    <nav id="header_menu">
      <ul className="menu">
        {menuItems.map((menuItem: iHeaderMenuItem) => {
          return (
            <li className="item" key={menuItem.label}>
              <DefaultButton
                label={menuItem.label}
                states={[DefaultButtonStates.Secondary]}
                icon={menuItem.icon}
                onClick={menuItem.onClick}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
