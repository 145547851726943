import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { SummaryItem } from 'components/pages/ConfiguratorPage/Summary/SummaryItem/SummaryItem';

export const ConceptSummaryItem: React.FC = () => {
  const { t } = useTranslation();
  const { concept, childGroupName } = useConfigurator();

  if (!concept) {
    return null;
  }

  return (
    <SummaryItem>
      <p className="paragraph">
        <b>{t('pages.configuratorPage.summary.amountOfChildren')}:</b> {childGroupName}<br />
        <b>{t('pages.configuratorPage.summary.amountOfDepartments')}:</b> {concept.departments}<br />
        <b>{t('common.BTA')}:</b> {concept.bta} {t('common.squareMeters')}<br />
        <b>{t('common.BRA')}:</b> {concept.loa} {t('common.squareMeters')}<br />
        <b>{t('common.childrenArea')}:</b> {concept.childrenArea} {t('common.squareMeters')}<br />
        <b>{t('pages.configuratorPage.summary.constructionTime')}*:</b> {t('common.amountOfMonths', {months: concept.constructionTime})}
      </p>
    </SummaryItem>
  );
};
