import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConceptSummaryItem } from './SummaryItem/ConceptSummaryItem/ConceptSummaryItem';
import { GeneralSummaryItem } from './SummaryItem/GeneralSummaryItem/GeneralSummaryItem';
import { ExteriorSummaryItem } from './SummaryItem/ExteriorSummaryItem/ExteriorSummaryItem';
import { InteriorSummaryItem } from './SummaryItem/InteriorSummaryItem/InteriorSummaryItem';
import { DescriptionSummaryItem } from './SummaryItem/DescriptionSummaryItem/DescriptionSummaryItem';
import './Summary.sass';

export const Summary: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { concept, pdfRef } = useConfigurator();

  if (!concept) {
    return null;
  }

  return (
    <div id="summary" className="summary" ref={pdfRef}>
      <div className="title-wrapper">
        <h3 className="title">{t(`concepts.${concept.key}.title`)}</h3>
      </div>
      <div className="content-wrapper">
        <div className="content">
          <ConceptSummaryItem />
          <GeneralSummaryItem />
          <ExteriorSummaryItem />
          <InteriorSummaryItem />
          <DescriptionSummaryItem />
        </div>
      </div>
    </div>
  );
};
