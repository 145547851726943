import { useConfigurator } from 'hooks/useConfigurator';
import { Category } from 'interfaces/Configurator';
import { ExteriorViewDropdown } from './ExteriorViewDropdown/ExteriorViewDropdown';
import './ExteriorViewDropdownList.sass';

export const ExteriorViewDropdownList: React.FC = () => {
  const { configOptions } = useConfigurator();

  return (
    <>
      {configOptions.exterior.map((category: Category) => {
        return (
          <ExteriorViewDropdown
            category={category}
            key={category.id}
          />
        );
      })}
    </>
  );
};
