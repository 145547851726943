import { useConfigurator } from 'hooks/useConfigurator';
import { ExteriorViewCanvas } from 'components/pages/ConfiguratorPage/ExteriorView/ExteriorViewLeftWrapper/ExteriorViewCanvas/ExteriorViewCanvas';
import { InteriorImageList } from 'components/pages/ConfiguratorPage/InteriorView/InteriorViewLeftWrapper/InteriorViewCanvas/InteriorImageList/InteriorImageList';
import './OverviewViewGallery.sass';

export const OverviewViewGallery: React.FC = () => {
  const {imagesRef} = useConfigurator();

  return (
    <ul id="overview_view_gallery" className="overview_view_gallery" ref={imagesRef}>
      <li className="item">
        <ExteriorViewCanvas />
      </li>
      <InteriorImageList />
    </ul>
  );
};
