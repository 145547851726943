import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { Dropdown } from 'components/partial/Dropdown/Dropdown';
import { DropdownText } from 'components/partial/Dropdown/DropdownText/DropdownText';

export const OutdoorEnvironmentDropdown: React.FC = () => {
  const { t } = useTranslation();
  const { concept } = useConfigurator();

  if (!concept) {
    return null;
  }

  return (
    <Dropdown title={t('pages.configuratorPage.views.exteriorView.outdoorEnvironment.title')}>
      <DropdownText>
        <p className="paragraph">
          {t('pages.configuratorPage.views.exteriorView.outdoorEnvironment.text.0', {groundworkCost: concept.groundwork.cost.toLocaleString()})}<br />
          {t('pages.configuratorPage.views.exteriorView.outdoorEnvironment.text.1')}
        </p>
      </DropdownText>
    </Dropdown>
  );
};
