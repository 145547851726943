import { BiteriorView } from '../BiteriorView/BiteriorView';
import { ExteriorViewLeftWrapper } from './ExteriorViewLeftWrapper/ExteriorViewLeftWrapper';
import { ExteriorViewRightWrapper } from './ExteriorViewRightWrapper/ExteriorViewRightWrapper';

export const ExteriorView: React.FC = () => {
  return (
    <BiteriorView name="exterior">
      <ExteriorViewLeftWrapper />
      <ExteriorViewRightWrapper />
    </BiteriorView>
  );
};
