import { useMemo } from 'react';
import { useConfigurator } from 'hooks/useConfigurator';
import { FloorValues } from 'interfaces/Configurator';

interface InteriorImageListProps {
  large?: boolean;
}

interface Image {
  src: string;
}

export const InteriorImageList: React.FC<InteriorImageListProps> = ({ large = false }) => {
  const { config, concept, floor } = useConfigurator();

  const images = useMemo((): Image[] => {
    if (!concept) return [];
    return [
      { src: `/images/concepts/interior_${config.interior.selectedOptions.materials.selectedOption}_1${large ? '_large' : ''}.jpg` },
      { src: `/images/concepts/interior_${config.interior.selectedOptions.materials.selectedOption}_2${large ? '_large' : ''}.jpg` },
      { src: `/images/concepts/${concept.key}/interior_${config.interior.selectedOptions.materials.selectedOption}_3${large ? '_large' : ''}.jpg` },
      { src: `/images/concepts/${concept.key}/interior_${config.interior.selectedOptions.materials.selectedOption}_4${large ? '_large' : ''}.jpg` },
      { src: `/images/concepts/${concept.key}/interior_${config.interior.selectedOptions.materials.selectedOption}_5${large ? '_large' : ''}.jpg` },
    ];
  }, [concept, config, large]);

  const colors = useMemo((): Image[] => {
    if (!concept) return [];
    return [
      { src: `/images/concepts/interior_materials_${config.interior.selectedOptions.materials.selectedOption}${large ? '_large' : ''}.jpg` },
    ];
  }, [concept, config, large]);

  const layouts = useMemo((): Image[] => {
    if (!concept) return [];
    const _layouts: Image[] = [
      { src: `/images/concepts/${concept.key}/interior_${config.interior.selectedOptions.materials.selectedOption}_view_1f.jpg` },
    ];
    if (floor === FloorValues.One) return _layouts;
    _layouts.push({ src: `/images/concepts/${concept.key}/interior_${config.interior.selectedOptions.materials.selectedOption}_view_2f.jpg` });
    return _layouts;
  }, [concept, config, floor]);

  const items = useMemo((): Image[] => {
    return [...images, ...colors, ...layouts];
  }, [images, colors, layouts]);

  return (
    <>
      {items.map((item: Image, index: number) => {
        return (
          <li className="item" key={index}>
            <img src={item.src} alt="" className="image" />
          </li>
        );
      })}
    </>
  );
};
