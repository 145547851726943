import { createContext, useContext, useState } from 'react';

interface LoaderContextProps {
  isLoaderOpen: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextProps>({
  isLoaderOpen: false,
  showLoader: () => {},
  hideLoader: () => {},
});

export const ProvideLoader: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const data = useProvideLoader();
  return <LoaderContext.Provider value={data}>{children}</LoaderContext.Provider>;
};

export const useLoader = (): LoaderContextProps => useContext(LoaderContext);

const useProvideLoader = (): LoaderContextProps => {
  const [isLoaderOpen, setIsLoaderOpen] = useState<boolean>(false);

  const showLoader = (): void => {
    setIsLoaderOpen(true);
  };

  const hideLoader = (): void => {
    setIsLoaderOpen(false);
  };

  return {
    isLoaderOpen,
    showLoader,
    hideLoader,
  };
};
