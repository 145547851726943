import { Link } from 'react-router-dom';
import { Icon, Icons } from '../Icon/Icon';
import './Button.sass';

export enum ButtonStates {
  Primary = '-primary',
  Secondary = '-secondary',
}

interface ButtonProps {
  label: string;
  onClick?: () => void;
  href?: string;
  icon?: Icons;
  disabled?: boolean;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({ label, onClick, href, icon, disabled = false, className = '' }) => {
  if (href) {
    return (
      <Link
        className={`button ${className}`}
        to={href}
      >
        <span className="label">{label}</span>
        {icon !== undefined ? <Icon icon={icon} /> : null}
      </Link>
    );
  }

  return (
    <button
      className={`button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="label">{label}</span>
      {icon !== undefined ? <Icon icon={icon} /> : null}
    </button>
  );
};
