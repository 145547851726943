import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';

export const initi18n = (): void => {
  i18n.use(initReactI18next).use(I18NextHttpBackend).init({
    lng: 'sv',
    fallbackLng: 'en',
    backend: {
      loadPath: '/languages/{{lng}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
  });
};
