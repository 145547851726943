import './SummaryItem.sass';

interface SummaryItemProps extends React.PropsWithChildren {
  title?: string;
  className?: string;
}

export const SummaryItem: React.FC<SummaryItemProps> = ({ children, title, className = '' }) => {
  return (
    <div className={`summary_item ${className}`}>
      {title && (
        <div className="title-wrapper">
          <h3 className="title">{title}</h3>
        </div>
      )}
      <div className="content-wrapper">
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
};
