import { useBiteriorView } from '../useBiteriorView';
import { Splash } from 'components/partial/Splash/Splash';
import { LayoutList } from 'components/pages/ConfiguratorPage/LayoutList/LayoutList';

export const LayoutSplash: React.FC = () => {
  const { isLayout, closeLayout } = useBiteriorView();

  return (
    <Splash
      isOpen={isLayout}
      onCloseClick={closeLayout}
      className="biterior_view_splash"
    >
      <LayoutList large={true} />
    </Splash>
  );
};
