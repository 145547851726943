import './Page.sass';

interface PageProps extends React.PropsWithChildren {
  id?: string;
  className?: string;
}

export const Page: React.FC<PageProps> = ({ children, id, className = '' }) => {
  return (
    <div id={id} className={`page ${id} ${className}`}>
      {children}
    </div>
  );
};
