import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { useCalculator } from 'hooks/useCalculator';
import { SummaryItem } from 'components/pages/ConfiguratorPage/Summary/SummaryItem/SummaryItem';
import { truncateDecimals } from 'helpers/MathHelpers';

export const GeneralSummaryItem: React.FC = () => {
  const { t } = useTranslation();
  const { concept } = useConfigurator();
  const { estimatedTotalCost, costPerBTAExcl, costPerBTAIncl, rentalCharge, annualCharge, primaryEnergy, carbonFootprint } = useCalculator();

  if (!concept) {
    return null;
  }

  return (
    <SummaryItem title={t('pages.configuratorPage.summary.globallyCalculation')}>
      <p className="paragraph">
        <b>{t('pages.configuratorPage.summary.conceptBaseCost')}:</b> {truncateDecimals(concept.cost / 1000000)} {t('common.millionUnits')}<br />
        <b>{t('pages.configuratorPage.summary.groundCost')}**:</b> {truncateDecimals(concept.groundwork.cost / 1000000)} {t('common.millionUnits')}<br />
        <b>{t('common.estimatedTotalCost')}:</b> {estimatedTotalCost} {t('common.millionUnits')}<br />
        <b>{t('pages.configuratorPage.summary.costPerBTAExcl')}:</b> {costPerBTAExcl.toLocaleString()} {t('common.unitsPerSquareMeters')}<br />
        <b>{t('pages.configuratorPage.summary.costPerBTAIncl')}:</b> {costPerBTAIncl.toLocaleString()} {t('common.unitsPerSquareMeters')}<br />
        {/*<b>{t('common.rentalCharge')}***:</b> {rentalCharge} {t('common.unitsPerSquareMetersBRAPerYear')}<br />
        <b>{t('common.rentalChargePerYear')}***:</b> {annualCharge} {t('common.millionUnits')}<br />*/}
        <b>{t('common.primaryEnergy')}:</b> {primaryEnergy} {t('common.kilowattHoursPerSquareMeters')}<br />
        <b>{t('common.carbonFootprint')}****:</b> {carbonFootprint} {t('common.kilogramsCO2ePerSquareMeters')}
      </p>
    </SummaryItem>
  );
};
