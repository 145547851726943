import { createContext, useContext, useState } from 'react';

interface BiteriorViewContextProps {
  splashIsOpen: boolean;
  isFullScreen: boolean;
  openFullScreen: () => void;
  closeFullScreen: () => void;
  isLayout: boolean;
  openLayout: () => void;
  closeLayout: () => void;
}

const BiteriorViewContext = createContext<BiteriorViewContextProps>({
  splashIsOpen: false,
  isFullScreen: false,
  openFullScreen: () => {},
  closeFullScreen: () => {},
  isLayout: false,
  openLayout: () => {},
  closeLayout: () => {},
});

export const ProvideBiteriorView: React.FC<React.PropsWithChildren<any>> = ({ children }: any) => {
  const data = useProvideBiteriorView();
  return <BiteriorViewContext.Provider value={data}>{children}</BiteriorViewContext.Provider>;
};

export const useBiteriorView = (): BiteriorViewContextProps => useContext(BiteriorViewContext);

const useProvideBiteriorView = (): BiteriorViewContextProps => {
  const [splashIsOpen, setSplashIsOpen] = useState<boolean>(false);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const [isLayout, setLayout] = useState<boolean>(false);

  const openFullScreen = (): void => {
    setFullScreen(true);
    setSplashIsOpen(true);
  };

  const closeFullScreen = (): void => {
    setFullScreen(false);
    setSplashIsOpen(false);
  };

  const openLayout = (): void => {
    setLayout(true);
    setSplashIsOpen(true);
  };

  const closeLayout = (): void => {
    setLayout(false);
    setSplashIsOpen(false);
  };

  return {
    splashIsOpen,
    isFullScreen,
    openFullScreen,
    closeFullScreen,
    isLayout,
    openLayout,
    closeLayout,
  };
};
