import './Container.sass';

interface ContainerProps extends React.PropsWithChildren {
  grow?: boolean;
  className?: string;
}

export const Container: React.FC<ContainerProps> = ({ children, grow = false, className = '' }) => {
  return (
    <div className={`container ${grow ? '-grow' : ''} ${className}`}>
      {children}
    </div>
  );
};
