import { createContext, useCallback, useContext, useMemo } from 'react';
import { useConfigurator } from './useConfigurator';
import { Biterior, Category, CategoryItem, CategoryItemTypeProps, Configurator, ConfiguratorOptions } from 'interfaces/Configurator';
import { truncateDecimals } from 'helpers/MathHelpers';

interface CalculatorContextProps {
  carbonFootprint: number;
  rentalCharge: number;
  annualCharge: number;
  estimatedTotalCost: number;
  costPerBTAExcl: number;
  costPerBTAIncl: number;
  primaryEnergy: number;
}

const CalculatorContext = createContext<CalculatorContextProps>({
  carbonFootprint: 0,
  rentalCharge: 0,
  annualCharge: 0,
  estimatedTotalCost: 0,
  costPerBTAExcl: 0,
  costPerBTAIncl: 0,
  primaryEnergy: 0,
});

export const ProvideCalculator: React.FC<React.PropsWithChildren<any>> = ({ children }: any) => {
  const data = useProvideCalculator();
  return <CalculatorContext.Provider value={data}>{children}</CalculatorContext.Provider>;
};

export const useCalculator = (): CalculatorContextProps => useContext(CalculatorContext);

const useProvideCalculator = (): CalculatorContextProps => {
  const { config, configOptions, concept } = useConfigurator();

  const getCategoryValues = useCallback((categories: Category[], biterior: Biterior, itemKey: keyof CategoryItemTypeProps): number => {
    if (!concept) return 0;
    return categories.map((category: Category) => {
      const selectedOption = category.options.find((item: CategoryItem) => item.id === biterior.selectedOptions[category.key].selectedOption);
      const selectedAddonIds = biterior.selectedOptions[category.key].selectedAddons;
      const selectedAddons = category.addons.filter((item: CategoryItem) => selectedAddonIds.indexOf(item.id) > -1);
      const selectedAddonValues = selectedAddons.reduce((accumulator: number, item: CategoryItem) => {
        return accumulator + item[itemKey][concept.key];
      }, 0);
      return (selectedOption ? selectedOption[itemKey][concept.key] : 0) + selectedAddonValues;
    }).reduce((a: number, c: number) => a + c, 0);
  }, [concept]);

  const getCategoryValuesSum = useCallback((options: ConfiguratorOptions, config: Configurator, itemKey: keyof CategoryItemTypeProps): number => {
    const exterior = getCategoryValues(configOptions.exterior, config.exterior, itemKey);
    const interior = getCategoryValues(configOptions.interior, config.interior, itemKey);
    return exterior + interior;
  }, [getCategoryValues, configOptions.exterior, configOptions.interior]);

  const carbonFootprint = useMemo(() => {
    if (!concept) return 0;
    const sum = getCategoryValuesSum(configOptions, config, 'co2');
    const value = (concept.co2 + sum) / concept.bta;
    return truncateDecimals(value, 0);
  }, [getCategoryValuesSum, configOptions, config, concept]);

  const rentalCharge = useMemo(() => {
    if (!concept) return 0;
    const sum = getCategoryValuesSum(configOptions, config, 'rent');
    return truncateDecimals((concept.rent + sum) + concept.groundwork.rent, 1);
  }, [getCategoryValuesSum, configOptions, config, concept]);

  const annualCharge = useMemo(() => {
    if (!concept) return 0;
    return truncateDecimals((rentalCharge * concept.loa) / 1000000, 2);
  }, [concept, rentalCharge]);

  const estimatedTotalCost = useMemo(() => {
    if (!concept) return 0;
    const sum = getCategoryValuesSum(configOptions, config, 'cost');
    return truncateDecimals((concept.cost + sum + concept.groundwork.cost) / 1000000);
  }, [getCategoryValuesSum, configOptions, config, concept]);

  const costPerBTAExcl = useMemo(() => {
    if (!concept) return 0;
    const sum = getCategoryValuesSum(configOptions, config, 'cost');
    return Math.floor((concept.cost + sum) / concept.bta);
  }, [getCategoryValuesSum, configOptions, config, concept]);

  const costPerBTAIncl = useMemo(() => {
    if (!concept) return 0;
    const sum = getCategoryValuesSum(configOptions, config, 'cost');
    return Math.floor((concept.cost + sum + concept.groundwork.cost) / concept.bta);
  }, [getCategoryValuesSum, configOptions, config, concept]);

  const primaryEnergy = useMemo(() => {
    if (!concept) return 0;
    const sum = getCategoryValuesSum(configOptions, config, 'kwh');
    return truncateDecimals(concept.kwh + sum, 1);
  }, [getCategoryValuesSum, configOptions, config, concept]);

  return {
    carbonFootprint,
    rentalCharge,
    annualCharge,
    estimatedTotalCost,
    costPerBTAExcl,
    costPerBTAIncl,
    primaryEnergy,
  };
};
