import './LeftWrapper.sass';

interface LeftWrapperProps extends React.PropsWithChildren {
  className?: string;
}

export const LeftWrapper: React.FC<LeftWrapperProps> = ({ children, className }) => {
  return (
    <div id="left_wrapper" className={className}>
      {children}
    </div>
  );
};
