import { EmailSentResult, FileUploadedResult } from 'interfaces/ApiResponse';
import { post } from './ApiService';

const apiUrl = process.env.REACT_APP_API_BASEURL;
const headers = {
  'x-functions-key': process.env.REACT_APP_API_HEADER_KEY,
};

export const storePDF = async (dataUrl: string): Promise<FileUploadedResult> => {
  const blob = await (await fetch(dataUrl)).blob();
  const file = new File([blob], 'document.pdf', {type: 'application/pdf', lastModified: (new Date()).getDate()});
  const formData = new FormData();
  formData.append('File', file);
  return post<FileUploadedResult>(`${apiUrl}/api/StorePdf`, formData, headers);
};

export const sendConfirmation = async (email: string, fileName: string, sendbcc: boolean): Promise<EmailSentResult> => {
  const formData = new FormData();
  formData.append('recipient', email);
  formData.append('data', fileName);
  formData.append('sendBCC', `${sendbcc}`);
  return post<EmailSentResult>(`${apiUrl}/api/SendConfirmation`, formData, headers);
};
