import { useTranslation } from 'react-i18next';
import { SquareButton, SquareButtonStates } from 'components/partial/Button/SquareButton/SquareButton';
import { Icons } from 'components/partial/Icon/Icon';
import './Splash.sass';

interface SplashProps extends React.PropsWithChildren {
  isOpen: boolean;
  onCloseClick: () => void;
  className?: string;
}

export const Splash: React.FC<SplashProps> = ({ children, isOpen, onCloseClick, className = '' }) => {
  const { t } = useTranslation();

  return (
    <div id="splash" className={`${className} ${isOpen ? '-open' : ''}`}>
      {children}
      <SquareButton
        label={t('common.close')}
        icon={Icons.Close}
        states={[SquareButtonStates.Default, SquareButtonStates.Large]}
        onClick={onCloseClick}
      />
    </div>
  );
};
