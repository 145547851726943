import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionList, OptionListItem } from '../OptionList';
import { ChildGroupValues, FloorValues } from 'interfaces/Configurator';

interface FloorOptionListProps {
  selectedFloor: FloorValues;
  selectedChildGroup: ChildGroupValues;
  onFloorChange: (value: FloorValues) => void;
}

export const FloorOptionList: React.FC<FloorOptionListProps> = ({ selectedFloor, selectedChildGroup, onFloorChange}) => {
  const { t } = useTranslation();

  const floors = useMemo((): OptionListItem[] => {
    return [
      {
        label: t('pages.configuratorPage.views.conceptView.floors.options.0.title'),
        value: FloorValues.One,
        disabled: selectedChildGroup === ChildGroupValues.Large,
        note: {
          text: t('pages.configuratorPage.views.conceptView.floors.options.0.note'),
          visible: selectedChildGroup === ChildGroupValues.Small,
        },
      },
      {
        label: t('pages.configuratorPage.views.conceptView.floors.options.1.title'),
        value: FloorValues.Two,
        disabled: selectedChildGroup === ChildGroupValues.Small,
        note: {
          text: t('pages.configuratorPage.views.conceptView.floors.options.1.note'),
          visible: selectedChildGroup === ChildGroupValues.Large,
        },
      },
    ];
  }, [selectedChildGroup, t]);

  return (
    <OptionList
      title={t('pages.configuratorPage.views.conceptView.floors.title')}
      options={floors}
      currentValue={selectedFloor}
      className="floor_list"
      onItemChange={onFloorChange}
    />
  );
};
