import React from 'react';
import ReactDOM from 'react-dom/client';
import { ProvideLoader } from './hooks/useLoader';
import { ProvideAlert } from './hooks/useAlert';
import { ProvideModals } from './hooks/useModals';
import { ProvideConfigurator } from './hooks/useConfigurator';
import { ProvideCalculator } from './hooks/useCalculator';
import App from './App';
import './styles/main.sass';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ProvideLoader>
      <ProvideAlert>
        <ProvideModals>
          <ProvideConfigurator>
            <ProvideCalculator>
              <App />
            </ProvideCalculator>
          </ProvideConfigurator>
        </ProvideModals>
      </ProvideAlert>
    </ProvideLoader>
  </React.StrictMode>
);
