import { useConfigurator } from 'hooks/useConfigurator';
import { useBiteriorView } from '../useBiteriorView';
import { ConfiguratorSteps } from 'interfaces/Configurator';
import { Splash } from 'components/partial/Splash/Splash';
import { ExteriorViewCanvas } from 'components/pages/ConfiguratorPage/ExteriorView/ExteriorViewLeftWrapper/ExteriorViewCanvas/ExteriorViewCanvas';
import { InteriorViewCanvas } from 'components/pages/ConfiguratorPage/InteriorView/InteriorViewLeftWrapper/InteriorViewCanvas/InteriorViewCanvas';

export const CanvasSplash: React.FC = () => {
  const { step } = useConfigurator();
  const { isFullScreen, closeFullScreen } = useBiteriorView();
  
  return (
    <Splash
      isOpen={isFullScreen}
      onCloseClick={closeFullScreen}
      className="biterior_view_splash"
    >
      <>
        {step === ConfiguratorSteps.Exterior ? <ExteriorViewCanvas large={true} /> : null}
        {step === ConfiguratorSteps.Interior ? <InteriorViewCanvas large={true} /> : null}
      </>
    </Splash>
  );
};
