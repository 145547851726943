import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { Category, CategoryItem } from 'interfaces/Configurator';
import { truncateDecimals } from 'helpers/MathHelpers';
import { SummaryItem } from '../SummaryItem';
import { SummaryItemTable, SummaryItemTableItem, SummaryItemTableItemValues } from 'components/pages/ConfiguratorPage/Summary/SummaryItem/SummaryItemTable/SummaryItemTable';

export const ExteriorSummaryItem: React.FC = () => {
  const { t } = useTranslation();
  const { config, concept, configOptions } = useConfigurator();

  const exterior = useMemo((): SummaryItemTableItem[] => {
    if (!concept) return [];
    const _exterior: SummaryItemTableItem[] = configOptions.exterior.map((category: Category) => {
      let values: SummaryItemTableItemValues[] = [];
      const selectedOption = category.options.filter((option: CategoryItem) => option.concepts.indexOf(concept.key) > -1).find((option: CategoryItem) => option.id === config.exterior.selectedOptions[category.key].selectedOption);
      const selectedAddons = category.addons.filter((option: CategoryItem) => option.concepts.indexOf(concept.key) > -1).filter((addon: CategoryItem) => config.exterior.selectedOptions[category.key].selectedAddons.indexOf(addon.id) > -1);
      if (selectedOption) {
        const label = t(`categories.exterior.${category.key}.options.${selectedOption.id}.label`);
        const details = t(`categories.exterior.${category.key}.options.${selectedOption.id}.details`).replace('\n', ', ');
        values.push({
          text: `${label} (${details})`,
          cost: `${selectedOption.cost[concept.key].toLocaleString()} ${t('common.units')}`,
        });
      }
      if (selectedAddons.length) {
        const addonValues: SummaryItemTableItemValues[] = selectedAddons.map((addon: CategoryItem) => {
          const label = t(`categories.exterior.${category.key}.options.${addon.id}.label`);
          const details = t(`categories.exterior.${category.key}.options.${addon.id}.details`);
          return {
            text: `${label} (${details})`,
            cost: `${addon.cost[concept.key].toLocaleString()} ${t('common.units')}`,
          };
        });
        values = [...values, ...addonValues];
      }
      return {
        label: t(`categories.exterior.${category.key}.title`),
        values: values,
      };
    });
    _exterior.push({
      label: undefined,
      values: [{
        text: `${t('pages.configuratorPage.summary.groundCost')}**`,
        cost: `${truncateDecimals(concept.groundwork.cost / 1000000)} ${t('common.millionUnits')}`,
        bold: true,
      }],
    });
    return _exterior;
  }, [configOptions, config, concept, t]);

  if (!concept) {
    return null;
  }

  return (
    <SummaryItem title={t('pages.configuratorPage.summary.exteriorSelections')}>
      <SummaryItemTable items={exterior} />
    </SummaryItem>
  );
};
