import { useRef, useState } from 'react';
import { RightWrapper } from 'components/pages/ConfiguratorPage/RightWrapper/RightWrapper';
import { TabMenu, TabMenuItem } from 'components/partial/TabMenu/TabMenu';
import { OverviewViewGallery } from './OverviewViewGallery/OverviewViewGallery';
import { LayoutList } from 'components/pages/ConfiguratorPage/LayoutList/LayoutList';
import { Summary } from 'components/pages/ConfiguratorPage/Summary/Summary';
import './OverviewViewRightWrapper.sass';

enum OverviewViewTabMenuValues {
  Gallery,
  Layout,
  Summary,
}

export const OverviewViewRightWrapper: React.FC = () => {
  const reference = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<OverviewViewTabMenuValues>(OverviewViewTabMenuValues.Gallery);

  const tabs: TabMenuItem[] = [
    { label: 'Visa bilder', value: OverviewViewTabMenuValues.Gallery },
    { label: 'Visa planritning', value: OverviewViewTabMenuValues.Layout },
    { label: 'Visa sammanfattning', value: OverviewViewTabMenuValues.Summary },
  ];

  const onTabClick = (value: OverviewViewTabMenuValues): void => {
    setCurrentTab(value);
    let className = '';
    if (value === OverviewViewTabMenuValues.Gallery) { className = 'overview_view_gallery'; }
    else if (value === OverviewViewTabMenuValues.Layout) { className = 'layout_list'; }
    else { className = 'summary'; }
    const targets = reference.current?.getElementsByClassName(className) || [];
    const target = targets[0];
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <RightWrapper className="overview_view_right_wrapper" reference={reference}>
      <div className="tab_menu-wrapper">
        <TabMenu
          items={tabs}
          selectedItem={currentTab}
          onTabClick={onTabClick}
        />
      </div>
      <div className="tab_content-wrapper">
        <div className="tab_content">
          <OverviewViewGallery />
          <LayoutList />
          <Summary />
        </div>
      </div>
    </RightWrapper>
  );
};
