import { Button } from 'components/partial/Button/Button';
import { Icons } from 'components/partial/Icon/Icon';
import './DefaultButton.sass';

export enum DefaultButtonStates {
  Primary = '-primary',
  Secondary = '-secondary',
}

interface DefaultButtonProps {
  label: string;
  states?: DefaultButtonStates[];
  onClick?: () => void;
  href?: string;
  icon?: Icons;
  disabled?: boolean;
}

export const DefaultButton: React.FC<DefaultButtonProps> = ({ label, states = [DefaultButtonStates.Primary], onClick, href, icon, disabled = false }) => {
  return (
    <Button
      label={label}
      onClick={onClick}
      href={href}
      icon={icon}
      disabled={disabled}
      className={`default_button ${states.join(' ')}`}
    />
  );
};
