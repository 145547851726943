import { useTranslation } from 'react-i18next';
import { RightWrapper } from 'components/pages/ConfiguratorPage/RightWrapper/RightWrapper';
import { TabMenu, TabMenuItem } from 'components/partial/TabMenu/TabMenu';
import { Summary } from '../../Summary/Summary';
import { DefaultButton } from 'components/partial/Button/DefaultButton/DefaultButton';
import { Icons } from 'components/partial/Icon/Icon';
import './BiteriorViewRightWrapper.sass';

interface BiteriorRightWrapperProps extends React.PropsWithChildren {
  tabs: TabMenuItem[];
  currentTab: any;
  showSummary: boolean;
  onTabClick: (currentTab: any) => void;
  onNextClick: () => void;
  className?: string;
}

export const BiteriorViewRightWrapper: React.FC<BiteriorRightWrapperProps> = ({ children, tabs, currentTab, showSummary, onTabClick, onNextClick, className = '' }) => {
  const { t } = useTranslation();

  return (
    <RightWrapper className={`biterior_view_right_wrapper ${className}`}>
      <div className="tab_menu-wrapper">
        <TabMenu
          items={tabs}
          selectedItem={currentTab}
          onTabClick={onTabClick}
        />
      </div>
      <div className="tab_content-wrapper">
        <div className="tab_content">
          <div className="content-wrapper">
            {showSummary ? <Summary /> : children}
          </div>
          <div className="button-wrapper">
            <DefaultButton
              label={t('common.goOn')}
              icon={Icons.ArrowRight}
              onClick={onNextClick}
            />
          </div>
        </div>
      </div>
    </RightWrapper>
  );
};
