import { LeftWrapper } from '../../LeftWrapper/LeftWrapper';
import './BiteriorViewLeftWrapper.sass';

interface BiteriorLeftWrapperProps extends React.PropsWithChildren {
  className?: string;
}

export const BiteriorViewLeftWrapper: React.FC<BiteriorLeftWrapperProps> = ({ children, className = '' }) => {
  return (
    <LeftWrapper className={`biterior_view_left_wrapper ${className}`}>
      {children}
    </LeftWrapper>
  );
};
