import { Container } from 'components/partial/Container/Container';
import { ProvideBiteriorView } from './useBiteriorView';
import { CanvasSplash } from './CanvasSplash/CanvasSplash';
import { LayoutSplash } from './LayoutSplash/LayoutSplash';
import './BiteriorView.sass';

interface BiteriorViewProps extends React.PropsWithChildren {
  name: string;
}

const BiteriorViewContainer: React.FC<BiteriorViewProps> = ({ children, name }) => {
  return (
    <>
      <div id="biterior_view" className={`${name}_view`}>
        <Container grow={true}>
          <div className={`biterior ${name}`}>
            {children}
          </div>
        </Container>
      </div>
      <CanvasSplash />
      <LayoutSplash />
    </>
  );
};

export const BiteriorView: React.FC<BiteriorViewProps> = ({ children, name }) => {
  return (
    <ProvideBiteriorView>
      <BiteriorViewContainer name={name}>
        {children}
      </BiteriorViewContainer>
    </ProvideBiteriorView>
  );
};
