import { useTranslation } from 'react-i18next';
import { useConfigurator } from 'hooks/useConfigurator';
import { ConfiguratorSteps, ChildGroupValues, FloorValues } from 'interfaces/Configurator';
import { Container } from 'components/partial/Container/Container';
import { DefaultButton } from 'components/partial/Button/DefaultButton/DefaultButton';
import { ChildGroupOptionList } from './OptionList/ChildGroupOptionList/ChildGroupOptionList';
import { FloorOptionList } from './OptionList/FloorOptionList/FloorOptionList';
import { Icons } from 'components/partial/Icon/Icon';
import {ReactComponent as BuddyGreen} from 'assets/images/buddy-green.svg';
import './ConceptView.sass';

export const ConceptView: React.FC = () => {
  const { t } = useTranslation();

  const { setStep, childGroup, setChildGroup, floor, setFloor } = useConfigurator();

  const onChildGroupChange = (childGroup: ChildGroupValues): void => {
    setChildGroup(childGroup);
    if (childGroup === ChildGroupValues.Small && floor === FloorValues.Two) {
      setFloor(FloorValues.One);
    } else if (childGroup === ChildGroupValues.Large && floor === FloorValues.One) {
      setFloor(FloorValues.Two);
    }
  };

  return (
    <div id="concept_view">
      <Container className={'concept_view_container'}>
        <div className="concept">
          <div className="content-wrapper">
            <div className="content">
              <div className="instruction-wrapper">
                <div className="instruction">
                  <div className="title-wrapper">
                    <h1 className="title">
                      {t('pages.configuratorPage.views.conceptView.title.0')}
                      <br />
                      {t('pages.configuratorPage.views.conceptView.title.1')}
                    </h1>
                  </div>
                  <div className="text-wrapper">
                    <p className="paragraph">{t('pages.configuratorPage.views.conceptView.text.0')}</p>
                    <p className="paragraph">{t('pages.configuratorPage.views.conceptView.text.1')}</p>
                  </div>
                </div>
              </div>
              <hr className="separator" />
              <div className="child_group_list-wrapper">
                <ChildGroupOptionList
                  selectedChildGroup={childGroup}
                  onChildGroupChange={onChildGroupChange}
                />
              </div>
              <hr className="separator" />
              <div className="floor_list-wrapper">
                <FloorOptionList
                  selectedFloor={floor}
                  selectedChildGroup={childGroup}
                  onFloorChange={setFloor}
                />
              </div>
            </div>
          </div>
          <div className="bottom-wrapper">
            <div className="bottom">
              <div className="buddy-wrapper">
                <BuddyGreen />
              </div>
              <div className="button-wrapper">
                <DefaultButton
                  label={t('pages.configuratorPage.views.conceptView.startBuilding')}
                  icon={Icons.ArrowRight}
                  onClick={() => {setStep(ConfiguratorSteps.Exterior);}}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
