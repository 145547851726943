import { Container } from 'components/partial/Container/Container';
import { OverviewViewLeftWrapper } from './OverviewViewLeftWrapper/OverviewViewLeftWrapper';
import { OverviewViewRightWrapper } from './OverviewViewRightWrapper/OverviewViewRightWrapper';
import './OverviewView.sass';

export const OverviewView: React.FC = () => {
  return (
    <div id="overview_view">
      <Container grow={true}>
        <div className="overview">
          <OverviewViewLeftWrapper />
          <OverviewViewRightWrapper />
        </div>
      </Container>
    </div>
  );
};
