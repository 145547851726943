import { useConfigurator } from 'hooks/useConfigurator';
import './ExteriorViewCanvas.sass';

interface ExteriorViewCanvasProps {
  large?: boolean;
  className?: string;
}

export const ExteriorViewCanvas: React.FC<ExteriorViewCanvasProps> = ({ large = false, className }) => {
  const { config, concept } = useConfigurator();

  const sunPanelId = 301;

  if (!concept) {
    return null;
  }

  return (
    <div id="exterior_canvas" className={className}>
      <img src={`/images/concepts/${concept.key}/exterior_base${large ? '_large' : ''}.png`} alt="" className="base" />
      <img src={`/images/concepts/${concept.key}/exterior_facade_${config.exterior.selectedOptions.facade.selectedOption}${large ? '_large' : ''}.png`} alt="" className="layer -facade" />
      <img src={`/images/concepts/${concept.key}/exterior_roof_${config.exterior.selectedOptions.roof.selectedOption}${large ? '_large' : ''}.png`} alt="" className="layer -roof" />
      {config.exterior.selectedOptions.climate.selectedAddons.indexOf(sunPanelId) > -1 ? <img src={`/images/concepts/${concept.key}/exterior_climate_${sunPanelId}${large ? '_large' : ''}.png`} alt="" className="layer -climate" /> : null}
    </div>
  );
};
