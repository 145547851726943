import { createContext, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoader } from './useLoader';
import { useAlert } from './useAlert';
import { PDF } from 'classes/PDF';
import {
  CalculationValues,
  Category,
  ChildGroup,
  ChildGroupValues,
  Concept,
  ConceptCollection,
  Concepts,
  Configurator,
  ConfiguratorOptions,
  ConfiguratorSteps,
  FloorValues
} from 'interfaces/Configurator';
import { getConcepts, getConfiguratorOptions } from 'services/DataService';
import { sendConfirmation, storePDF } from 'services/BlobStorageService';

interface ConfiguratorContextProps {
  config: Configurator;
  updateConfigExteriorOption: (category: string, categoryItemId: number) => void;
  updateConfigExteriorAddons: (category: string, categoryItemId: number) => void;
  updateConfigInteriorOption: (category: string, categoryItemId: number) => void;
  updateConfigInteriorAddons: (category: string, categoryItemId: number) => void;
  step: ConfiguratorSteps;
  setStep: (step: ConfiguratorSteps) => void;
  concept: Concept | undefined;
  setConcept: (concept: Concepts) => void;
  childGroup: ChildGroupValues;
  setChildGroup: (childGroup: ChildGroupValues) => void;
  childGroups: ChildGroup[];
  childGroupName: string;
  floor: FloorValues;
  setFloor: (floor: FloorValues) => void;
  isSubmitted: boolean;
  setIsSubmitted: (isSubmitted: boolean) => void;
  onSubmit: (email: string, name: string, calculations: CalculationValues, sendbcc: boolean) => void;
  configOptions: ConfiguratorOptions;
  imagesRef: RefObject<HTMLUListElement> | null;
  pdfRef: RefObject<HTMLDivElement> | null;
}

const ConfiguratorContext = createContext<ConfiguratorContextProps>({
  config: {
    exterior: {
      selectedOptions: {},
    },
    interior: {
      selectedOptions: {},
    },
  },
  updateConfigExteriorOption: () => {},
  updateConfigExteriorAddons: () => {},
  updateConfigInteriorOption: () => {},
  updateConfigInteriorAddons: () => {},
  step: ConfiguratorSteps.Concept,
  setStep: () => {},
  concept: undefined,
  setConcept: () => {},
  childGroup: ChildGroupValues.Small,
  setChildGroup: () => {},
  childGroups: [],
  childGroupName: '',
  floor: FloorValues.One,
  setFloor: () => {},
  isSubmitted: false,
  setIsSubmitted: () => {},
  onSubmit: () => {},
  configOptions: {
    exterior: [],
    interior: [],
  },
  imagesRef: null,
  pdfRef: null,
});

export const ProvideConfigurator: React.FC<React.PropsWithChildren<any>> = ({ children }: any) => {
  const data = useProvideConfigurator();
  return <ConfiguratorContext.Provider value={data}>{children}</ConfiguratorContext.Provider>;
};

export const useConfigurator = (): ConfiguratorContextProps => useContext(ConfiguratorContext);

const useProvideConfigurator = (): ConfiguratorContextProps => {
  const { t } = useTranslation();
  const { showLoader, hideLoader } = useLoader();
  const { showAlert } = useAlert();

  const imagesRef = useRef<HTMLUListElement>(null);
  const pdfRef = useRef<HTMLDivElement>(null);

  const [config, setConfig] = useState<Configurator>({
    exterior: {
      selectedOptions: {},
    },
    interior: {
      selectedOptions: {},
    },
  });
  const [step, setStep] = useState<ConfiguratorSteps>(ConfiguratorSteps.Concept);
  const [concept, setConcept] = useState<Concept | undefined>(undefined);
  const [concepts, setConcepts] = useState<ConceptCollection>({});
  const [childGroup, setChildGroup] = useState<ChildGroupValues>(ChildGroupValues.Small);
  const [floor, setFloor] = useState<FloorValues>(FloorValues.One);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [configOptions, setConfigOptions] = useState<ConfiguratorOptions>({
    exterior: [],
    interior: [],
  });
  const [configOptionsFetched, setConfigOptionsFetched] = useState<boolean>(false);
  const [conceptsFetched, setConceptsFetched] = useState<boolean>(false);

  const childGroups = useMemo((): ChildGroup[] => {
    return [
      { label: t('pages.configuratorPage.views.conceptView.childGroups.options.0'), value: ChildGroupValues.Small },
      { label: t('pages.configuratorPage.views.conceptView.childGroups.options.1'), value: ChildGroupValues.Medium },
      { label: t('pages.configuratorPage.views.conceptView.childGroups.options.2'), value: ChildGroupValues.Large },
    ];
  }, [t]);

  const childGroupName = useMemo((): string => {
    const ch = childGroups.find(ch => ch.value === childGroup);
    return ch ? ch.label : '';
  }, [childGroups, childGroup]);

  const updateConfigExteriorOption = (category: string, categoryItemId: number): void => {
    const _config = {...config};
    _config.exterior.selectedOptions[category].selectedOption = categoryItemId;
    setConfig(_config);
  };

  const updateConfigExteriorAddons = (category: string, categoryItemId: number): void => {
    const _config = {...config};
    const index = _config.exterior.selectedOptions[category].selectedAddons.indexOf(categoryItemId);
    if (index === -1) {
      _config.exterior.selectedOptions[category].selectedAddons.push(categoryItemId);
    } else {
      _config.exterior.selectedOptions[category].selectedAddons.splice(index, 1);
    }
    setConfig(_config);
  };

  const updateConfigInteriorOption = (category: string, categoryItemId: number): void => {
    const _config = {...config};
    _config.interior.selectedOptions[category].selectedOption = categoryItemId;
    setConfig(_config);
  };

  const updateConfigInteriorAddons = (category: string, categoryItemId: number): void => {
    const _config = {...config};
    const index = _config.interior.selectedOptions[category].selectedAddons.indexOf(categoryItemId);
    if (index === -1) {
      _config.interior.selectedOptions[category].selectedAddons.push(categoryItemId);
    } else {
      _config.interior.selectedOptions[category].selectedAddons.splice(index, 1);
    }
    setConfig(_config);
  };

  const onSubmit = async (email: string, name: string, calculations: CalculationValues, sendbcc: boolean): Promise<void> => {
    if (!concept) return;
    showLoader();
    const pdf = new PDF({
      name,
      concept,
      config,
      configOptions,
      childGroup: childGroupName,
      floor,
      calculations,
      t
    });
    const blob = await pdf.createDocument();

    let fileName: string | undefined;
    try {
      const { result } = await storePDF(blob);
      fileName = result;
    } catch (e: any) {
      onError();
    }

    if (fileName) {
      try {
        await sendConfirmation(email, fileName, sendbcc);
        onSuccess();
      } catch (e: any) {
        onError();
      }
    }
  };

  const onSuccess = (): void => {
    hideLoader();
    setIsSubmitted(true);
  };

  const onError = (): void => {
    hideLoader();
    setIsSubmitted(false);
    showAlert();
  };

  const setDefaultConfig = useCallback((options: ConfiguratorOptions): void => {
    const _config = {...config};
    options.exterior.forEach((category: Category) => {
      _config.exterior.selectedOptions[category.key] = {
        selectedOption: category.options.length ? category.options[0].id : null,
        selectedAddons: [],
      };
    });
    options.interior.forEach((category: Category) => {
      _config.interior.selectedOptions[category.key] = {
        selectedOption: category.options.length ? category.options[0].id : null,
        selectedAddons: [],
      };
    });
    setConfig(_config);
  }, [config]);

  const setCurrentConcept = useCallback((concept: Concepts): void => {
    const _concept = concepts[concept];
    setConcept(_concept);
  }, [concepts]);

  const init = useCallback(async (): Promise<void> => {
    const _options = await getConfiguratorOptions();
    const _concepts = await getConcepts();
    setConfigOptions(_options);
    setConfigOptionsFetched(true);
    setConcepts(_concepts);
    setConceptsFetched(true);
    setDefaultConfig(_options);
    setCurrentConcept(Concepts.A);
  }, [setDefaultConfig, setCurrentConcept]);

  useEffect(() => {
    if (childGroup === ChildGroupValues.Small && floor === FloorValues.One) {
      setCurrentConcept(Concepts.A);
    } else if (childGroup === ChildGroupValues.Medium && floor === FloorValues.One) {
      setCurrentConcept(Concepts.B);
    } else if (childGroup === ChildGroupValues.Medium && floor === FloorValues.Two) {
      setCurrentConcept(Concepts.C);
    } else if (childGroup === ChildGroupValues.Large && floor === FloorValues.Two) {
      setCurrentConcept(Concepts.D);
    }
  }, [childGroup, floor, setCurrentConcept]);

  useEffect(() => {
    if (!configOptionsFetched && !conceptsFetched) {
      init();
    }
  }, [configOptionsFetched, conceptsFetched, init]);

  return {
    config,
    updateConfigExteriorOption,
    updateConfigExteriorAddons,
    updateConfigInteriorOption,
    updateConfigInteriorAddons,
    step,
    setStep,
    concept,
    setConcept: setCurrentConcept,
    childGroup,
    setChildGroup,
    childGroups,
    childGroupName,
    floor,
    setFloor,
    isSubmitted,
    setIsSubmitted,
    onSubmit,
    configOptions,
    imagesRef,
    pdfRef,
  };
};
