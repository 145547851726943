import jsPDF from 'jspdf';
import {TFunction} from 'i18next';
import {
  CalculationValues,
  Category,
  CategoryItem,
  Concept,
  Configurator,
  ConfiguratorOptions,
  FloorValues
} from 'interfaces/Configurator';
import { truncateDecimals } from 'helpers/MathHelpers';

interface PDFProps {
  name: string;
  concept: Concept;
  config: Configurator;
  configOptions: ConfiguratorOptions;
  childGroup: string;
  floor: FloorValues;
  calculations: CalculationValues;
  t: TFunction;
}

export class PDF {

  private doc = new jsPDF({
    orientation: 'portrait',
    format: 'a4',
    unit: 'mm',
    compress: true,
  });

  private logo: HTMLImageElement | undefined = undefined;

  private font = 'helvetica';

  private fontSize = 8;

  private x = 16;

  private y = 16;

  private maxWidth = 0;

  private imageWidth = 0;

  private name = '';

  private concept: Concept = {} as Concept;

  private config: Configurator = {} as Configurator;

  private configOptions: ConfiguratorOptions = {} as ConfiguratorOptions;

  private childGroup = '';

  private floor: FloorValues = FloorValues.One;

  private calculations: CalculationValues = {} as CalculationValues;

  private t: any;

  private exteriorImageIndex = 0;

  private exteriorImages: string[] = [];

  private interiorImageIndex = 0;

  private interiorImages: string[] = [];

  constructor(options: PDFProps) {
    this.name = options.name;
    this.concept = options.concept;
    this.config = options.config;
    this.configOptions = options.configOptions;
    this.childGroup = options.childGroup;
    this.floor = options.floor;
    this.calculations = options.calculations;
    this.t = options.t;
    this.maxWidth = this.doc.internal.pageSize.width - (this.x * 2);
    this.imageWidth = this.maxWidth - (this.x * 2);
    this.exteriorImages = [
      `/images/concepts/${this.concept.key}/exterior_base.png`,
      `/images/concepts/${this.concept.key}/exterior_facade_${this.config.exterior.selectedOptions.facade.selectedOption}.png`,
      `/images/concepts/${this.concept.key}/exterior_roof_${this.config.exterior.selectedOptions.roof.selectedOption}.png`,
    ];
    if (this.config.exterior.selectedOptions.climate.selectedAddons.indexOf(301) > -1) {
      this.exteriorImages.push(`/images/concepts/${this.concept.key}/exterior_climate_301.png`);
    }
    this.interiorImages = [
      `/images/concepts/interior_${this.config.interior.selectedOptions.materials.selectedOption}_1.jpg`,
      `/images/concepts/interior_${this.config.interior.selectedOptions.materials.selectedOption}_2.jpg`,
      `/images/concepts/${this.concept.key}/interior_${this.config.interior.selectedOptions.materials.selectedOption}_3.jpg`,
      `/images/concepts/${this.concept.key}/interior_${this.config.interior.selectedOptions.materials.selectedOption}_4.jpg`,
      `/images/concepts/${this.concept.key}/interior_${this.config.interior.selectedOptions.materials.selectedOption}_5.jpg`,
    ];
  }

  async createDocument(): Promise<string> {
    await this.setLogo();
    this.addHeader();
    await this.addExteriorImage(0);
    await this.addInteriorImage(0);
    await this.addColorImage();
    await this.addViewImage();
    await this.addLayoutImage();
    this.addSummary();
    this.addFooter();
    return this.getDataUrl();
  };

  private saveDocument(): void {
    const d = new Date();
    const date = d.toLocaleDateString();
    const time = d.toLocaleTimeString().replaceAll(':','-');
    this.doc.save(`SkanskaForskolebyggaren-${date}_${time}.pdf`);
  };

  private getDataUrl = (): string => {
    return this.doc.output('datauristring');
  };

  private incrementY(y: number): void {
    this.y = this.y + y;
  }

  private setY(y: number): void {
    this.y = y;
  }

  private async setLogo(): Promise<void> {
    this.logo = await this.loadImage('images/general/logo.png');
    return new Promise((resolve, reject) => resolve());
  }

  private addHeader(): void {
    if (!this.logo) return;
    const width = 35;
    const height = width * (this.logo.height / this.logo.width);
    this.addImage(this.logo, 'png', this.x, this.y, width, height);
    this.setY(20);
    const title = this.t(`concepts.${this.concept.key}.title`) + (this.name.length ? `, ${this.name}` : '');
    this.doc.setFontSize(8);
    this.doc.text(title, this.x + this.maxWidth, this.y, { align: 'right', maxWidth: this.maxWidth - width });
    this.setY(16);
    this.incrementY(height + 8);
  }

  private addFooter(): void {
    const pages = this.doc.getNumberOfPages();
    const role = this.t('modals.contact.contact.role');
    const name = this.t('modals.contact.contact.name');
    const phone = this.t('modals.contact.contact.phone');
    const email = this.t('modals.contact.contact.email');
    let i = 1;
    while (i <= pages) {
      this.doc.setPage(i);
      this.addText(`${role}: ${name}, ${phone}, ${email}`, this.x, this.doc.internal.pageSize.height - 12);
      this.addText(`${i}/${pages}`, this.doc.internal.pageSize.width - 16, this.doc.internal.pageSize.height - 12);
      i++;
    }
  }

  private async addExteriorImage(index: number, y?: number): Promise<void> {
    if (this.exteriorImageIndex <= (this.exteriorImages.length - 1)) {
      const image: HTMLImageElement = await this.loadImage(this.exteriorImages[index]);
      const height = this.imageWidth * (image.height / image.width);
      this.addImage(image, 'png', this.x + 16, this.y, this.imageWidth, height);
      this.exteriorImageIndex = this.exteriorImageIndex + 1;
      await this.addExteriorImage(this.exteriorImageIndex, height);
    } else {
      this.incrementY(y || 0);
      return new Promise((resolve, reject) => resolve());
    }
  };

  private async addInteriorImage(index: number, y?: number): Promise<void> {
    if (index === 2) {
      this.addPage();
    } else {
      this.incrementY(2);
    }
    if (this.interiorImageIndex <= (this.interiorImages.length - 1)) {
      const image: HTMLImageElement = await this.loadImage(this.interiorImages[index]);
      const height = this.imageWidth * (image.height / image.width);
      this.addImage(image, 'png', this.x + 16, this.y, this.imageWidth, height);
      this.interiorImageIndex = this.interiorImageIndex + 1;
      this.incrementY(height);
      await this.addInteriorImage(this.interiorImageIndex, height);
    } else {
      this.incrementY(y || 0);
      return new Promise((resolve, reject) => resolve());
    }
  }

  private async addColorImage(): Promise<void> {
    this.addPage();
    const image: HTMLImageElement = await this.loadImage(`/images/concepts/interior_materials_${this.config.interior.selectedOptions.materials.selectedOption}.jpg`);
    const height = this.imageWidth * (image.height / image.width);
    this.addImage(image, 'jpeg', this.x + 16, this.y, this.imageWidth, height);
    this.incrementY(height);
    return new Promise((resolve, reject) => resolve());
  }

  private async addViewImage(): Promise<void> {
    this.incrementY(8);
    const image1: HTMLImageElement = await this.loadImage(`/images/concepts/${this.concept.key}/interior_${this.config.interior.selectedOptions.materials.selectedOption}_view_1f.jpg`);
    const height1 = this.imageWidth * (image1.height / image1.width);
    this.addImage(image1, 'jpeg', this.x + 16, this.y, this.imageWidth, height1);
    this.incrementY(height1);
    if (this.floor === FloorValues.Two) {
      this.incrementY(8);
      const image2: HTMLImageElement = await this.loadImage(`/images/concepts/${this.concept.key}/interior_${this.config.interior.selectedOptions.materials.selectedOption}_view_2f.jpg`);
      const height2 = this.imageWidth * (image2.height / image2.width);
      this.addImage(image2, 'jpeg', this.x + 16, this.y, this.imageWidth, height2);
      this.incrementY(height2);
      return new Promise((resolve, reject) => resolve());
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  }

  private async addLayoutImage(): Promise<void> {
    if (this.floor === FloorValues.Two) {
      this.addPage();
    } else {
      this.incrementY(8);
    }
    const image1: HTMLImageElement = await this.loadImage(`/images/concepts/${this.concept.key}/interior_layout_1f.jpg`);
    const height1 = this.imageWidth * (image1.height / image1.width);
    this.addImage(image1, 'jpeg', this.x + 16, this.y, this.imageWidth, height1);
    if (this.floor === FloorValues.Two) {
      this.incrementY(height1 + 8);
      const image2: HTMLImageElement = await this.loadImage(`/images/concepts/${this.concept.key}/interior_layout_2f.jpg`);
      const height2 = this.imageWidth * (image2.height / image2.width);
      this.addImage(image2, 'jpeg', this.x + 16, this.y, this.imageWidth, height2);
      return new Promise((resolve, reject) => resolve());
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  }

  private addSummary(): void {
    this.addPage();
    this.addTitle(this.t('pdf.title'));
    this.addConceptSummary();
    this.addGeneralSummary();
    this.addSubTitle(this.t('pages.configuratorPage.summary.exteriorSelections'));
    this.addExteriorSummary();
    this.addLine();
    this.addSubTitle(this.t('pages.configuratorPage.summary.interiorSelections'));
    this.addInteriorSummary();
    this.addLine();
    this.addDescriptionSummary();
  };

  private addSummaryRow(label: string, value: string, width: number, x = 0): void {
    this.addText(`${label}`, this.x + x, this.y, width, true);
    this.addText(value, this.x + width + x, this.y);
    this.incrementY(4);
  }

  private addDescriptionSummaryRow(text: string, y: number): void {
    this.addText(text, this.x, this.y, this.maxWidth);
    this.incrementY(y);
  }

  private addConceptSummary(): void {
    const width = this.maxWidth / 4;
    this.setY(40);
    this.addSubTitle(this.t(`concepts.${this.concept.key}.title`));
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.amountOfChildren')}:`, this.childGroup, width);
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.amountOfDepartments')}:`, this.concept.departments.toString(), width);
    this.addSummaryRow(`${this.t('common.BTA')}:`, `${this.concept.bta} ${this.t('common.squareMeters')}`, width);
    this.addSummaryRow(`${this.t('common.BRA')}:`, `${this.concept.loa} ${this.t('common.squareMeters')}`, width);
    this.addSummaryRow(`${this.t('common.childrenArea')}:`, `${this.concept.childrenArea} ${this.t('common.squareMeters')}`, width);
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.constructionTime')}:`,`${this.t('common.amountOfMonths', {months: this.concept.constructionTime})}`, width);
  }

  private addGeneralSummary(): void {
    const width = this.maxWidth / 4;
    this.setY(40);
    this.addSubTitle(this.t('pages.configuratorPage.summary.globallyCalculation'), width * 2);
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.conceptBaseCost')}:`, `${truncateDecimals(this.concept.cost / 1000000)} ${this.t('common.millionUnits')}`, width, width * 2);
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.groundCost')}**:`, `${truncateDecimals(this.concept.groundwork.cost / 1000000)} ${this.t('common.millionUnits')}`, width, width * 2);
    this.addSummaryRow(`${this.t('common.estimatedTotalCost')}:`, `${this.calculations.estimatedTotalCost} ${this.t('common.millionUnits')}`, width, width * 2);
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.costPerBTAExcl')}:`, `${this.calculations.costPerBTAExcl.toLocaleString()} ${this.t('common.unitsPerSquareMeters')}`, width, width * 2);
    this.addSummaryRow(`${this.t('pages.configuratorPage.summary.costPerBTAIncl')}:`, `${this.calculations.costPerBTAIncl.toLocaleString()} ${this.t('common.unitsPerSquareMeters')}`, width, width * 2);
    /*this.addSummaryRow(`${this.t('common.rentalCharge')}***:`, `${this.calculations.rentalCharge} ${this.t('common.unitsPerSquareMetersBRAPerYear')}`, width, width * 2);
    this.addSummaryRow(`${this.t('common.rentalChargePerYear')}***:`, `${this.calculations.annualCharge} ${this.t('common.millionUnits')}`, width, width * 2);*/
    this.addSummaryRow(`${this.t('common.primaryEnergy')}:`, `${this.calculations.primaryEnergy} ${this.t('common.kilowattHoursPerSquareMeters')}`, width, width * 2);
    this.addSummaryRow(`${this.t('common.carbonFootprint')}****:`, `${this.calculations.carbonFootprint} ${this.t('common.kilogramsCO2ePerSquareMetersPdf')}`, width, width * 2);
    this.addLine();
  }

  private addExteriorSummary(): void {
    const gap = 10;
    const valueWidth = this.maxWidth / 6;
    const labelWidth = valueWidth * 5;
    this.configOptions.exterior.forEach((category: Category) => {
      this.addText(`${this.t(`categories.exterior.${category.key}.title`)}`, this.x, this.y, this.maxWidth, true);
      this.incrementY(4);
      const selectedOption = category.options.filter((option: CategoryItem) => option.concepts.indexOf(this.concept.key) > -1).find((option: CategoryItem) => option.id === this.config.exterior.selectedOptions[category.key].selectedOption);
      const selectedAddons = category.addons.filter((option: CategoryItem) => option.concepts.indexOf(this.concept.key) > -1).filter((addon: CategoryItem) => this.config.exterior.selectedOptions[category.key].selectedAddons.indexOf(addon.id) > -1);
      if (selectedOption) {
        const label = this.t(`categories.exterior.${category.key}.options.${selectedOption.id}.label`);
        const details = this.t(`categories.exterior.${category.key}.options.${selectedOption.id}.details`).replace('\n', ', ');
        this.addText(`${label} (${details})`, this.x, this.y, labelWidth);
        this.addText(`${selectedOption.cost[this.concept.key].toLocaleString()} ${this.t('common.units')}`, labelWidth + this.x + gap, this.y, valueWidth - gap);
        this.incrementY(6);
      }
      if (selectedAddons.length) {
        selectedAddons.forEach((addon: CategoryItem) => {
          const label = this.t(`categories.exterior.${category.key}.options.${addon.id}.label`);
          const details = this.t(`categories.exterior.${category.key}.options.${addon.id}.details`);
          this.addText(`${label} (${details})`, this.x, this.y, labelWidth);
          this.addText(`${addon.cost[this.concept.key].toLocaleString()} ${this.t('common.units')}`, labelWidth + this.x + gap, this.y, valueWidth - gap);
          this.incrementY(6);
        });
      }
    });
    this.addText(`${this.t('pages.configuratorPage.summary.groundCost')}**:`, this.x, this.y, labelWidth, true);
    this.addText(`${truncateDecimals(this.concept.groundwork.cost / 1000000)} ${this.t('common.millionUnits')}`, labelWidth + this.x + gap, this.y, valueWidth - gap);
    this.incrementY(4);
  }

  private addInteriorSummary(): void {
    const gap = 10;
    const valueWidth = this.maxWidth / 6;
    const labelWidth = valueWidth * 5;
    this.configOptions.interior.forEach((category: Category) => {
      this.addText(`${this.t(`categories.interior.${category.key}.title`)}`, this.x, this.y, this.maxWidth, true);
      this.incrementY(4);
      const selectedOption = category.options.filter((option: CategoryItem) => option.concepts.indexOf(this.concept.key) > -1).find((option: CategoryItem) => option.id === this.config.interior.selectedOptions[category.key].selectedOption);
      const selectedAddons = category.addons.filter((option: CategoryItem) => option.concepts.indexOf(this.concept.key) > -1).filter((addon: CategoryItem) => this.config.interior.selectedOptions[category.key].selectedAddons.indexOf(addon.id) > -1);
      if (selectedOption) {
        const label = this.t(`categories.interior.${category.key}.options.${selectedOption.id}.label`);
        const details = this.t(`categories.interior.${category.key}.options.${selectedOption.id}.details`);
        this.addText(`${label} (${details})`, this.x, this.y, labelWidth);
        this.addText(`${selectedOption.cost[this.concept.key].toLocaleString()} ${this.t('common.units')}`, labelWidth + this.x + gap, this.y, valueWidth - gap);
        this.incrementY(8);
      }
      if (selectedAddons.length) {
        selectedAddons.forEach((addon: CategoryItem) => {
          const label = this.t(`categories.interior.${category.key}.options.${addon.id}.label`);
          const details = this.t(`categories.interior.${category.key}.options.${addon.id}.details`);
          this.addText(`${label} (${details})`, this.x, this.y, labelWidth);
          this.addText(`${addon.cost[this.concept.key].toLocaleString()} ${this.t('common.units')}`, labelWidth + this.x + gap, this.y, valueWidth - gap);
          this.incrementY(8);
        });
      }
    });
  }

  private addDescriptionSummary(): void {
    this.addDescriptionSummaryRow(this.t('pages.configuratorPage.summary.description.0'), 8);
    this.addDescriptionSummaryRow(this.t('pages.configuratorPage.summary.description.1'), 12);
    /*this.addDescriptionSummaryRow(this.t('pages.configuratorPage.summary.description.2'), 6);*/
    this.addDescriptionSummaryRow(this.t('pages.configuratorPage.summary.description.5'), 6);
    this.addDescriptionSummaryRow(this.t('pages.configuratorPage.summary.description.4'), 0);
  }

  private loadImage(imageUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => resolve(image);
      image.src = imageUrl;
    });
  }

  private addPage(): void {
    this.y = 12;
    this.doc.addPage();
    this.addHeader();
  };

  private addLine(): void {
    this.doc.setDrawColor(0, 0, 0);
    this.doc.line(this.x, this.y, this.doc.internal.pageSize.width - this.x, this.y);
    this.incrementY(6);
  }

  private addTitle(title: string): void {
    this.doc.setFont(this.font, 'bold');
    this.doc.setFontSize(16);
    this.doc.text(title, this.x, this.y);
    this.doc.setFont(this.font, 'normal');
    this.doc.setFontSize(this.fontSize);
    this.incrementY(8);
  }

  private addSubTitle(title: string, x = 0): void {
    this.doc.setFont(this.font, 'bold');
    this.doc.setFontSize(12);
    this.doc.setTextColor('#143275');
    this.doc.text(title, this.x + x, this.y);
    this.doc.setFont(this.font, 'normal');
    this.doc.setFontSize(this.fontSize);
    this.doc.setTextColor('#000000');
    this.incrementY(6);
  }

  private addText(text: string, x: number = this.x, y: number = this.y, maxWidth: number = this.maxWidth, bold = false): void {
    this.doc.setFont(this.font, bold ? 'bold' : 'normal');
    this.doc.text(text, x, y, { maxWidth });
    this.doc.setFont(this.font, 'normal');
  }

  private addImage(image: string | HTMLImageElement, format: string, x: number, y: number, width: number, height: number): void {
    this.doc.addImage(image, format, x, y, width, height, undefined, 'FAST');
  };

}
